import App from './App.vue'
import Vue from 'vue'

import store from './store'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

const moment = require('moment')
import 'moment/locale/es'
import 'moment/locale/pt-br'
import 'moment/locale/es-us'
import VueMoment from 'vue-moment'
Vue.use(VueMoment, { moment })

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
var defaultLanguage
var browserLanguage = (navigator.browserLanguage != undefined) ? navigator.browserLanguage : navigator.language

if (browserLanguage.substr(0, 2) == 'pt') {
    defaultLanguage = 'pt_br'
} else if (browserLanguage.substr(0, 2) == 'es') {
    defaultLanguage = 'es'
} else {
    defaultLanguage = 'en'
}

moment.locale(defaultLanguage)

const numberFormats = {
    'usd': {
        currency: {
            style: 'currency',
            currency: 'USD'
        }
    },
    'brl': {
        currency: {
            style: 'currency',
            currency: 'BRL'
        }
    }
}

const i18n = new VueI18n({
    numberFormats,
    locale: defaultLanguage,
    fallbackLocale: 'pt_br',
    messages: {
        'es': {
            ...ES
        },
        'en': {
            ...EN
        },
        'pt_br': {
            ...PT_BR
        }
    },
    silentTranslationWarn: true
})

import ES from '../public/assets/i18n/es'
import EN from '../public/assets/i18n/en'
import PT_BR from '../public/assets/i18n/pt_br'

import VueGoogleAnalytics from 'vue-analytics'
import VueGoogleAdsense from 'vue-google-adsense'
Vue.use(require('vue-script2'))

import Print from 'vue-printjs'
Vue.use(Print)

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCountryCode from "vue-country-code"
Vue.use(VueCountryCode)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


/*
  Components imported by the routes
*/
import Home from './components/Home.vue'
import Agreement from './components/Agreement.vue'

import error_404 from './components/error/404'

/*
  Routes
*/

const propsHandler = (route) => {
    // console.log(">> propsHandler", route)
    return true
}


var routes = [
    {
        path: "*",
        name: "error_404",
        component: error_404,
    },
    {
        path: "/",
        name: "home",
        props: propsHandler,
        component: Home,
        meta: {
            title: {
                en: 'Acquafy',
                pt_br: 'Acquafy',
                es: 'Acquafy'
            },
            metaTags: [{
                    name: 'keywords',
                    content: 'Acquafy'
                },
                {
                    name: 'author',
                    content: 'Acquafy'
                },
                {
                    name: 'description',
                    content: {
                        en: 'Home - Acquafy',
                        pt_br: 'Home - Acquafy',
                        es: 'Home - Acquafy'
                    }
                },
                {
                    property: 'og:description',
                    content: {
                        en: 'Home - Acquafy',
                        pt_br: 'Home - Acquafy',
                        es: 'Home - Acquafy'
                    }
                }
            ]
        }
    },
    {
        path: "/agreement",
        name: "agreement",
        props: propsHandler,
        component: Agreement,
        meta: {
            title: {
                en: 'Acquafy',
                pt_br: 'Acquafy',
                es: 'Acquafy'
            },
            metaTags: [{
                    name: 'keywords',
                    content: 'Acquafy'
                },
                {
                    name: 'author',
                    content: 'Acquafy'
                },
                {
                    name: 'description',
                    content: {
                        en: 'Home - Acquafy',
                        pt_br: 'Home - Acquafy',
                        es: 'Home - Acquafy'
                    }
                },
                {
                    property: 'og:description',
                    content: {
                        en: 'Home - Acquafy',
                        pt_br: 'Home - Acquafy',
                        es: 'Home - Acquafy'
                    }
                }
            ]
        }
    },
     {
        path: "/agreement",
        name: "agreement",
        props: propsHandler,
        component: Agreement,
        meta: {
            title: {
                en: 'Acquafy',
                pt_br: 'Acquafy',
                es: 'Acquafy'
            },
            metaTags: [{
                    name: 'keywords',
                    content: 'Acquafy'
                },
                {
                    name: 'author',
                    content: 'Acquafy'
                },
                {
                    name: 'description',
                    content: {
                        en: 'Home - Acquafy',
                        pt_br: 'Home - Acquafy',
                        es: 'Home - Acquafy'
                    }
                },
                {
                    property: 'og:description',
                    content: {
                        en: 'Home - Acquafy',
                        pt_br: 'Home - Acquafy',
                        es: 'Home - Acquafy'
                    }
                }
            ]
        }
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})


router.beforeEach((to, from, next) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title),
          lang = i18n.locale == "pt" ? 'pt_br' : i18n.locale

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title[lang].replace(" - Acquafy", "").replace("Acquafy", "")

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

    next()
})

router.afterEach((to, from) => {

    // const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
    // const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

})

Vue.mixin({
    methods: {
        setGoogleCookies(data) {

            if (data.googleanalytics_key && data.googleanalytics_key != '') {
                Vue.use(VueGoogleAnalytics, {
                    id: data.googleanalytics_key,
                    router
                })
                console.log(">> Google analytics defined", data.googleanalytics_key)
            }


            if (data.googleads_key && data.googleads_key != '') {
                Vue.use(VueGoogleAdsense.AutoAdsense, {
                    adClient: data.googleads_key
                })
                console.log(">> Google adSense defined", data.googleads_key)
            }

        },
    }
})


new Vue({
    i18n,
    store,
    router,
    render: h => h(App),
}).$mount('#app')
