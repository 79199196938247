<template>
<div style="min-height: 100vh;" class="page-404">
  <div class="bg-info text-white py-5 d-flex align-items-center justify-content-center">
      <div class="container py-5">
          <div class="row">
               <div class="col-md-2 text-center">
                    <p class="title-404"><i class="fa fa-exclamation-triangle fa-5x"></i><br/>Status Code: 404</p>
               </div>
               <div class="col-md-10">
                    <h3 class="title-404" v-html="$t('NOT_FOUND_TITLE')"></h3>
                    <p class="subtitle-404" v-html="$t('NOT_FOUND_MESSAGE')"></p>
                    <button class="btn btn-danger" @click.prevent="navigateTo('home')">{{$t('CONTINUE_NAVIGATING')}}</button>
               </div>
          </div>
     </div>

  </div>
</div>
</template>

<script>
export default {
     inject: ['navigateTo'],
}
</script>

<style lang="scss">

.page-404{
     .bg-info{
          height: 100vh;
          width: 100vw;
          position: fixed;
          top: 0;

          .title-404, .subtitle-404{
               color: #fff !important;
          }
     }
}

</style>
