const en = {
    "ESSENCIAL_COOKIES_LABEL": "Cookies strictly needed",
    "ESSENCIAL_COOKIES_DESCRIPTION": "These cookies are required for the operation of the site and can not be disabled on our systems.They are usually set only in response to actions made by you that are equivalent to a service request, such as defining your privacy preferences, logging in or filling forms.You can configure your browser to lock or alert you on these cookies, but some parts of the site will not work.These cookies do not store any personally identifiable information.",
    "PERFORMANCE_COOKIES_LABEL": "Performance and Analysis Cookies",
    "PERFORMANCE_COOKIES_DESCRIPTION": "These cookies allow us to count visits and sources of traffic so that we can measure and improve the performance of our site.They help us know which pages are the most and less popular and see how visitors move on the site.All the information these cookies collect are aggregated and therefore anonymous.If you do not allow these cookies, we will not know when you visited our site and we can not monitor your performance.",
    "FUNCTIONAL_COOKIES_LABEL": "Functional cookies",
    "FUNCTIONAL_COOKIES_DESCRIPTION": "These cookies allow the site to provide improved functionality and customization.They can be defined by us or by third party providers whose services we add to our pages.If you do not allow these cookies, some or all of these services may not work properly.",
    "TARGETING_COOKIES_LABEL": "Segmentation Cookies",
    "TARGETING_COOKIES_DESCRIPTION": "These cookies can be defined on our website by our advertising partners.They can be used by these companies to build a profile of their interests and show relevant ads on other sites.They do not store personal information directly, but are based on the exclusive ID of your browser and internet device.If you do not allow these cookies, you will have less directed advertising.",
    "COOKIES_MODAL_TITLE": "Privacy Preferences",
    "COOKIES_MODAL_DESCRIPTION": "When you visit any website, it can store or retrieve information in your browser, especially in the form of cookies.This information can be about you, your preferences or your device and are mainly used to make the site work as you expect.Information generally do not identify you directly, but can provide a more personalized web experience.As we respect your right to privacy, you can choose not to allow some types of cookies. Click on different categories titles to learn more and change our default settings.However, the blocking of some types of cookies can affect your experience on the website and the services we can offer. <br> <br> Read a <a href='/privacy-policy/{lang}' target='_blank'>Privacy Policy</a>",
    "COOKIES_DESCRIPTION": "We use cookies to help the operation of our site. If you consent, we will also use cookies to measure and improve the site and customize content and ads. We will share information with advertising and analysis partners.<br> <br> Read the <a href='/Privacy-policy/{lang}' target='_blank'>Privacy Policy</a>.",
    "MANAGE_COOKIES": "Manage Cookies",
    "ACCEPT_COOKIES": "To accept",
    "BENEFITS": "Benefits",
    "DIFFERENTIAL": "Differential",
    "PLANS": "Plans",
    "HOW_WORKS": "How does it work",
    "CUSTOMER_SERVICE": "Customer service",
    "WATER_AS_A_SERVICE": "waterAsAService",
    "SIGN_IN": "login",
    "SIGN_OUT": "logout",
    "MY_ACCOUNT": "My account",
    "CONTINUE_NAVIGATING": "Go to Home",
    "NOT_FOUND_TITLE": "Page not found",
    "NOT_FOUND_MESSAGE": "The page you attempt to access does not exist or you do not have permission to access it",
    "ALL_RIGHTS_RESERVED": "All rights reserved",
    "SUBMIT_ORDER": "Complete order",
    "SUBSCRIBE_NOW": "Subscribe now",
    "SOLVE_RECAPTCHA": "It is necessary to solve the recap",

    "SECTION_01_TITLE": "Acquafy alkaline water: new habits, more quality of life",
    "SECTION_01_SUBTITLE": "Is the water from your home or your company 100% pure?",
    "SECTION_01_DESCRIPTION": "You knew that with the purchase purifier you will have 100% alkaline water with pH above 9 to use the free will of bacteria, viruses, rust and chlorine. Ready to drink, cook, wash fruits and vegetables.",
    "DROP_01_TITLE": "Water alkaline",
    "DROP_02_TITLE": "4 Filters <br> Powerful",
    "DROP_02_DESCRIPTION": "PP Pillow <br> Alkalan Minerals <br> Scale Filter <br> Ultrafiltration UF",
    "FOR": "for",
    "DAILY": "per day",
    "MONTHLY": "per month *",

    "SECTION_02_TITLE": "Benefits of Alkaline Water Acquafy",
    "SECTION_03_TITLE": "Differentials of alkaline water purifiers Acquafy",
    "SECTION_04_TITLE": "Products and Plans Acquafy, choose the best for you",
    "SECTION_04_TITLE_02": "Ordering the Acquafy Purifier is very simple",
    "VALID_ZIPCODE": "Region available for installation",
    "INVALID_ZIPCODE": "Service unavailable for the selected region. Unfortunately we still do not have the lease service for the desired area",
    "NULL_ZIPCODE": "You need to inform the zip code",
    "ZIPCODE": "Zip code",
    "ZIPCODE_AVAILABILITY": "Check availability",
    "PROCEED": "Advance",
    "GO_BACK": "Go back",
    "ORDER_ON_ACQUAFY": "Order on Acquafy.com",
    "CHECK": "Check",

    "PERSONAL_DATA": "Personal data",
    "AGREEMENT": "Contract",
    "BILLING_DATA": "Billing data",
    "CONFIRMATION": "Confirmation",
    "LEARN_MORE": "Learn more",
    "MOST_POPULAR": "More leased",
    "PLAN_DETAILS": "Details of the plan",
    "PRODUCT_DETAILS": "Product Details",
    "PRODUCT_CHARACTERISTICS": "Product characteristics",
    "CERTIFICATIONS": "Certifications / EPA est. No. 99499-FL-1",
    "ADHESION_FEE": "Adhesion fee (single fee)",
    "MONTHLY_PAYMENT": "Monthly payment",
    "AGREEMENT_DURATION": "Contract duration",
    "MONTHS": "months",
    "CANCEL_ORDER": "Do you really want to cancel your order?",
    "YES": "Yes",
    "NO": "No",
    "REGISTER": "Register",    
    "BILLING": "Billing",
    "SELECTED_PLAN": "Selected Plan",
    "CONTINUE": "Continue",
    "DRIVER_LICENSE": "Driver license",
    "SOCIAL_SECURITY": "Social security",
    "PASSPORT": "Passport",
    "SELECT_AN_ID_TYPE": "Select an ID type...",
    "NAME": "Name",
    "LASTNAME": "Last name",
    "EMAIL_CONFIRMATION": "E-mail confirmation",
    "PHONE": "Telefone",
    "ID_NUMBER": "ID number",
    "AGREEMENT_ACCEPT": "<b>AUTHORIZATION</b>: The customer agrees to all terms and conditions contained in this Agreement and guarantees that the subscriber is authorized to adhere to and sign this Agreement. This Agreement is valid when signed electronically from the acceptance by the client during the execution of the contracting process carried out from the website https://acquafy.lease.",
    "MUST_AGREE": "You must to accept the terms of the contract to continue",
    "ADDRESS_LINE_1": "Address line 1",
    "ADDRESS_LINE_2": "Address line 2",
    "CITY": "City",
    "STATE": "State",
    "CARD_DATA": "Card data",
    "SHIPPING_ADDRESS": "Shipping address",
    "EMAIL_EQUAL": "The e-mails must be identical",
    "INVALID_ID": "Invalid ID",
    "SELECT_COUNTRY": "Select the country",
    "SELECT_STATE": "Select the state",
    "CARD_ALREADY_VALIDATED": "Verified card!",
    "BRAND": "Brand",
    "EXPIRATION": "Expiration",
    "PAYMENT_METHOD_REQUIRED": "Payment method required",
    "REPLACE_CARD": "Replace card",
    "TOTAL_FIRST_PAYMENT": "Total of the first payment",
    "NEXT_PAYMENTS": "Next monthly payments",
    "AGREEMENT_ACCEPTED": "Agreement accepted",
    "AGREEMENT_REJECTED": "Agreement rejected",
    "CARD_LAST_4": "Last four digits",
    "SUCCESS_MESSAGE": "Plan contracted successfully!",
    "SUCCESS_DESCRIPTION": "You will receive an email with the data of your order at a moment.",
    "READ_AGREEMENT": "Read the agreement",
    "SEE_PLAN": "Show selected plan",
    "HIDE_PLAN": "Hide selected plan",
    "SECTION_05_CIRCLE_01": "<b>Choose</b> the plan",
    "SECTION_05_CIRCLE_02": "<b>Subscribe</b> Online",
    "SECTION_05_CIRCLE_03": "<b>Receive</b> and install",
    "SECTION_05_CIRCLE_04": "<b>Enjoy!</b>",
    "FOOTER_COL_01_TITLE": "ACQUAFY AND COVID-19",  
    "FOOTER_COL_01_SUBTITLE": "You can buy quietly<br><br>Acquafy is committed to carefully follow all observations regarding the containment of Coronavirus propagation. From manufacturing to the delivery of your product, it underwent a rigorous hygiene.",
    "FOOTER_COL_02_TITLE": "ACQUAFY",
    "FOOTER_COL_03_TITLE": "CONTACT",  
    "FOLLOW_US": "Follow us",
    "SUBMIT_ORDER_COUNTDOWN": "This alert will be dismissed after {count} seconds...",
    "ID_FILE": "Photo of the document",
    "SEE_IMAGE": "See the image",
    "MAX_FILE_SIZE_ERROR": "The image should be less than 4MB"
}
export default en