import $ from 'jquery'
import axios from 'axios'

const state = {    
    customer: null,
    api: {
        customers: {
            link: "/api/customers",            
            getCustomer: "/api/customers/{customer}",
            editCustomer: "/api/customers/{customer}",       
        },
        notification: {
            new_customer: "/api/notification/new_customer",
        }
    }
}

const actions = {

    async getCustomer({ commit, state }, payload) {
        const url = payload.api.baseUrl + state.api.customers.getCustomer.replace("{customer}", payload.id)

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",            
            beforeSend: function(xhr) {
                console.log(">> Getting customer data (getCustomer)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + payload.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const customer = response
                    commit('MUTATE_CUSTOMER', customer)
                    console.log(">> Got customer data (getCustomer)!", customer)
                    return customer
                } else {
                    return {}
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getCustomer): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })
    },

    async saveCustomer({ commit, state }, payload = null) {

        const url = payload.api.baseUrl + state.api.customers.link

        return await $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: payload,
            beforeSend: function(xhr) {
                console.log(">> Saving new customer (saveCustomer)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + payload.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    const customer = response                    
                    console.log(">> Customer saved! (saveCustomer)!")
                    return customer
                } else {
                    return []
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(">> Error (saveCustomer): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })
    },


    async editCustomer({ commit, state }, payload = null) {

        const url = payload.api.baseUrl + state.api.customers.editCustomer.replace("{pre_customer}", payload.id)

        return await $.ajax({
            url: url,
            type: "put",
            dataType: "json",
            data: payload.data,
            beforeSend: function(xhr) {
                console.log(">> Updating existing customer (editCustomer)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + payload.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    const customer = response                    
                    console.log(">> Customer updated! (editCustomer)!")
                    return customer
                } else {
                    return []
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(">> Error (editCustomer): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },
}

const mutations = {
    MUTATE_CUSTOMER: (state, customer) => (state.customer = customer)
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}