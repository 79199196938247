<template>
  <div class="section">
      <div class="inner-section">

        <h1 style="width: 100%; text-align: center;" class='mb-5' v-html="$t('SECTION_02_TITLE')"></h1>

        <div class="carousel-wrapper">
          <Carousel                  
            class="carousel-section02"
            :margin="0" 
            :autoplay="true"                     
            :dots="false"
            :nav="false"
            :responsive="{
              0:{
                  items: 1
              },
              768:{
                  items: 2
              },
              990:{
                  items: 3
              },
              1200:{
                  items: 4
              }
            }"
          >

            <div class="carousel-section02-slide" v-for="(item, index) in carousel_section02_data" :key="index">                
              <div class="inner d-flex flex-column justify-content-start align-items-center">
                <img class='mb-4' :src="`/assets/images/section02/${item.image}`" :alt="item.title[$i18n.locale]">              
                <h3 class='mb-4' v-html="item.title[$i18n.locale]"></h3>
                <p v-html="item.text[$i18n.locale]"></p>
              </div>
            </div>

          </Carousel>
        </div>

      </div>
  </div>
</template>

<script>
import Carousel from 'vue-owl-carousel'
export default {
  components:{
    Carousel,        
  },
  data(){
    return{
      carousel_section02_data: [
        {
          title: {
            "pt_br": "Saúde",
            "en": "Health",
            "es": "Salud",
          },
          text: {
            "pt_br": "Com os purificadores Acquafy você terá 100% água alcalina, com pH acima de 9 para usar a vontade livre de bactérias, vírus, ferrugem e cloro.<br>Pronta para beber, cozinhar, lavar frutas e verduras.",
            "en": "With the Purifiers Acquafy you will have 100% alkaline water, with pH above 9 to use free will of bacteria, viruses, rust and chlorine. Ready to drink, cook, wash fruits and vegetables.",
            "es": "Con los purificadores Acquafy, tendrá un agua alcalina al 100%, con un pH superior al 9 para usar la libre albedrina de bacterias, virus, óxido y cloro. Listo para beber, cocinar, lavar frutas y verduras.",
          },
          image: "image01.jpg"
        },
        {
          title: {
            "pt_br": "Confiança",
            "en": "Trust",
            "es": "Confianza",
          },
          text: {
            "pt_br": "A Acquafy cuida de tudo!<br>Refis dos elementos filtrantes e garantia do produto por todo o período do contrato. Para você aproveitar seu tempo com o que realmente importa.",
            "en": "Acquafy takes care of everything! REFIS of the filter elements and product warranty throughout the contract period.For you to enjoy your time with what really matters.",
            "es": "¡Acquafy se encarga de todo! Refisos de los elementos del filtro y la garantía del producto durante todo el período del contrato.Para que disfrutes tu tiempo con lo que realmente importa.",
          },
          image: "image02.jpg"
        },
        {
          title: {
            "pt_br": "Qualidade e Performance",
            "en": "Quality and performance",
            "es": "Calidad y rendimiento",
          },
          text: {
            "pt_br": "Produtos com alta performande e tecnologia aplicada, que oferecem a melhor experiencia em água alcalina e deixam seu ambiente mais moderno e sofisticado.",
            "en": "Products with high performance and applied technology, which offer the best experienced alkaline water and leave your environment more modern and sophisticated.",
            "es": "Productos con alta tecnología y tecnología aplicada, que ofrecen el mejor agua alcalina con experiencia y dejan su entorno más moderno y sofisticado.",
          },
          image: "image03.jpg"
        }, 
        {
          title: {
            "pt_br": "Segurança",
            "en": "Safety",
            "es": "La seguridad",
          },
          text: {
            "pt_br": "O atendimento exclusivo Acquafy unido as mais rigorosas certificações proporcionam segurança em todas as etapas.",
            "en": "Exclusive attendance Acquafy attached the most stringent certifications provide security at all stages.",
            "es": "Asistencia exclusiva Acquafy adjunticada Las certificaciones más estrictas proporcionan seguridad en todas las etapas.",
          },
          image: "image04.jpg"
        },        
      ]
    }
  }
}
</script>

<style lang="scss">
  .owl-stage{
    display: table;
    height: 100%;    
    .owl-item{
      position: relative;
      display: table-cell !important;
      height: 100% !important;       
    }
  }
</style>

<style scoped lang="scss">

  .section{        
    background-color: #FFF7EC;
    min-height: calc(100vh - 125px) !important;     
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
  }

  .inner-section{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .carousel-wrapper{      
      width: 100%;
      .carousel-section02{        
        
        .carousel-section02-slide{
          height: 100%;
          padding: 10px 15px 15px 15px;
          
          .inner{        
            height: 100%;                    
            border-radius: 10px;
            border: 2px solid #C2C2C2;
            transition: all .4s ease;

            &:hover{
              border: 2px solid #F9B526;
              -webkit-box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
              -moz-box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
              box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);

              transition: all .4s ease;
            }

            img{
              border-radius: 8px 8px 0 0;
              position: relative;
              width: 100%;
            }
            h3{
              padding: 0 20px;
              text-align: left;
              width: 100%;
            }
            p{
              padding: 0 20px;
              text-align: left;
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1000px) {

    .inner-section{
    }
  }

  @media only screen and (max-width: 767px) {

    .inner-section{      
    }
  }

</style>