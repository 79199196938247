<template>
  <div class="section">
      <div class="inner-section">

        <h1 style="width: 100%; text-align: center;" class="mb-5" v-html="$t('SECTION_05_TITLE')"></h1>

        <div class="section05-content">
          <div>
            <span class="circle"><img src="/assets/images/section05/image01.png" alt="Circle"></span>
            <label v-html="$t('SECTION_05_CIRCLE_01')"></label>
          </div>
          
          <!-- <i class="fas" :class="{'fa-arrow-right mx-5': window.width > 768, 'fa-arrow-down my-3': window.width < 769}"></i> -->
          <i class="fas fa-arrow-right" :class="{'mx-5': window.width > 768, 'mx-2': window.width < 769}"></i>

          <div>
            <span class="circle"><img src="/assets/images/section05/image02.png" alt="Circle"></span>
            <label v-html="$t('SECTION_05_CIRCLE_02')"></label>
          </div>
          
          <!-- <i class="fas" :class="{'fa-arrow-right mx-5': window.width > 768, 'fa-arrow-down my-3': window.width < 769}"></i> -->
          <i class="fas fa-arrow-right" :class="{'mx-5': window.width > 768, 'mx-2': window.width < 769}"></i>

          <div>
            <span class="circle"><img src="/assets/images/section05/image03.png" alt="Circle"></span>
            <label v-html="$t('SECTION_05_CIRCLE_03')"></label>
          </div>
          
          <!-- <i class="fas" :class="{'fa-arrow-right mx-5': window.width > 768, 'fa-arrow-down my-3': window.width < 769}"></i> -->
          <i class="fas fa-arrow-right" :class="{'mx-5': window.width > 768, 'mx-2': window.width < 769}"></i>

          <div>
            <span class="circle"><img src="/assets/images/section05/image04.png" alt="Circle"></span>
            <label v-html="$t('SECTION_05_CIRCLE_04')"></label>
          </div>
          
        </div>

      </div>
  </div>
</template>

<script>
export default {
  props: ['window'],
  data(){
    return{

    }
  }
}
</script>

<style scoped lang="scss">

  .section{    
    min-height: calc(70vh - 125px) !important;     
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
  }

  .inner-section{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .section05-content{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .circle{
          width: 150px;
          height: 150px;
          border: 2px solid #2d77b3;
          border-radius: 50%;
          transition: all .4s ease;

          &:hover{
            transition: all .4s ease;
            border: 2px solid #F9B526;            
          }
          img{
            position: relative;
            width: 100%;
            height: auto;
          }        
        }

        label{
          margin-top: 10px;
          width: 100%;
          text-align: center;          
        }
      }
      i{
        color: #2d77b3;
        font-size: 30px;
        margin-top: 65px;
      }
    }
  }

  @media only screen and (max-width: 768px){

    .inner-section{

      .section05-content{        
        // flex-direction: column;

        div{
          .circle{
            width: 80px;
            height: 80px;
          }          
        }
        i{          
          font-size: 20px;
          margin-top: 30px;
        }
      }
    }
  }


  @media only screen and (min-width: 768px) and (max-width: 1000px) {

    .inner-section{
    }
  }

  @media only screen and (max-width: 767px) {

    .inner-section{      
    }
  }

</style>