<template>
  <div class="home">
    <Section01 id="section01"></Section01>    
    <Section02 id="section02"></Section02>
    <Section03 id="section03"></Section03>
    <Section04 id="section04"></Section04>
    <Section05 :window="window" id="section05"></Section05>    

    
  </div>
</template>

<script>
import Section01 from './sections/Section01'
import Section02 from './sections/Section02'
import Section03 from './sections/Section03'
import Section04 from './sections/Section04'
import Section05 from './sections/Section05'
export default {
  props: ['window'],
  components:{    
    Section01,
    Section02,
    Section03,
    Section04,
    Section05,    
  },  
}
</script>

<style lang="scss">


  fieldset{    
    width: 100%; 
    border: 2px solid #bfc3c4; 
    border-radius: 5px;

    legend{
      font-size: 18px;
      font-weight: bold;
      font-family: 'Montserrat Alternates Medium';
      color: #2d77b3;
      width: auto; text-align: center; padding: 0 40px;
    }
    padding: 30px;
    background-color: #fff;
  }
  .btn-yellow{
    border-color: #F9B526;
    background-color: #F9B526;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    transition: all .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      color: #fff;
      border-color: #FAC75B;
      background-color: #FAC75B;
      transition: all .4s ease;
    }
  }

  .btn-dark-blue{
    border-color: #1769ab;
    background-color: #1769ab;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    transition: all .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      color: #fff;
      border-color: #208de5;
      background-color: #208de5;
      transition: all .4s ease;
    }
  }

  .btn-light-blue{
    border-color: #02b6dc;
    background-color: #02b6dc;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    transition: all .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      color: #fff;
      border-color: #35d7f9;
      background-color: #35d7f9;
      transition: all .4s ease;
    }
  }


  .btn-white-darkblue{
    border-color: #1769ab;
    background-color: #FFF;
    color: #1769ab;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    transition: all .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      border-color: #1769ab;
    background-color: #1769ab;
    color: #fff;
      transition: all .4s ease;
    }
  }
  
  .home{
    padding-top: 125px;  

    .section{            
      min-height: calc(80vh - 125px);    
      padding: 5rem 6vw;  
      
      .line{
        width: 100%;      
      }
    }

    
  }

  @media only screen and (max-width: 767px) {
    .home{
      .section{
        padding: 20px 20px;
      }
    }
  }
</style>