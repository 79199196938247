<template>
  <div class="section">
    <div class="inner-section">

      <h1 style="width: 100%; text-align: center;" class="mb-5" v-html="$t('SECTION_03_TITLE')"></h1>

      <div class="mb-4 row line first-row">
        <div class="item col-lg-6 co l-md-6 col-sm-12">
          <div class="row inner mx-1 my-0">
            <div class="col-lg-6 col-lg-6 col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <h3 class='p-4' v-html="blocks[0].title[$i18n.locale]"></h3>
              <p class="pt-0 pb-4 px-4 m-0" v-html="blocks[0].text[$i18n.locale]"></p>
            </div>
            <div class="p-0 col-lg-6 col-lg-6 col-sm-6">
              <img :src="`/assets/images/section03/${blocks[0].image}`" :alt="blocks[0].title[$i18n.locale]">
            </div>
          </div>
        </div>

        <div class="item col-lg-6 col-md-6 col-sm-12">
          <div class="row inner mx-1 my-0">
            <div class="col-lg-6 col-lg-6 col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <h3 class='pt-4 pl-4 pb-4' v-html="blocks[1].title[$i18n.locale]"></h3>
              <p class="pt-0 pb-4 px-4 m-0" v-html="blocks[1].text[$i18n.locale]"></p>
            </div>
            <div class="p-0 col-lg-6 col-lg-6 col-sm-6">
              <img :src="`/assets/images/section03/${blocks[1].image}`" :alt="blocks[1].title[$i18n.locale]">
            </div>
          </div>
        </div>
      </div>

      <div class="row line second-row">
        <div class="col-12 item">
          <div class="row inner mx-1 my-0">
            <div class="col-lg-6 col-lg-6 col-sm-6 d-flex flex-column justify-content-center align-items-center">
              <h3 class='pt-4 pl-4 pb-4' v-html="blocks[2].title[$i18n.locale]"></h3>
              <p class="pt-0 pb-4 px-4 m-0" v-html="blocks[2].text[$i18n.locale]"></p>
            </div>
            <div class="p-0 col-lg-6 col-lg-6 col-sm-6">
              <img :src="`/assets/images/section03/${blocks[2].image}`" :alt="blocks[2].title[$i18n.locale]">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {  
  data(){
    return{
      blocks: [
        {
          title: {
            "pt_br": "Os melhores produtos a sua disposição",
            "en": "The best products at your disposal",
            "es": "Los mejores productos a tu disposición.",
          },
          text: {
            "pt_br": "A Acquafy tem a opção ideal para você, garantindo água alcalina em um produto que se adequa ao seu estilo de vida.",
            "en": "Acquafy has the ideal option for you, ensuring alkaline water in a product that suits your lifestyle.",
            "es": "La Acquafy tiene la opción ideal para usted, asegurando agua alcalina en un producto que se adapte a su estilo de vida.",
          },
          image: "image01.jpg"
        },
        {
          title: {
            "pt_br": "Planos que se adequam a sua necessidade",
            "en": "Plans that suit their need",
            "es": "Planes que se adaptan a su necesidad",
          },
          text: {
            "pt_br": "As mais tecnológicas soluções em água alcalina em opções de planos para todos os estilos e necessidades.",
            "en": "The most technological alkaline water solutions in flat options for all styles and needs.",
            "es": "Las soluciones de agua alcalina más tecnológicas en opciones planas para todos los estilos y necesidades.",
          },
          image: "image02.png"
        },
        {
          title: {
            "pt_br": "Facilidade e tranquilidade em todos os momentos",
            "en": "Ease and tranquility at all times",
            "es": "Facilidad y tranquilidad en todo momento.",
          },
          text: {
            "pt_br": "Refis dos elementos filtrantes e garantia do produto por todo o período do contrato.<br>Purificadores fáceis de instalar.<br>Refis fáceis de substituir.<br>(Instalação incluída para Orlando e região)",
            "en": "Refis of the filter elements and product warranty throughout the contract period. <br> Easy to install purifiers. <br> Easy to replace. <br>(Included for Orlando and region)",
            "es": "Refisos de los elementos del filtro y la garantía del producto durante todo el período del contrato. <br> Fácil de instalar purificadores. <br> Fácil de reemplazar. (Incluido para Orlando y Región)",
          },
          image: "image03.png"
        },        
      ]
    }
  }
}
</script>

<style scoped lang="scss">

  .section{        
    background-color: #fff;
    min-height: calc(100vh - 125px) !important;     
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
  }

  .inner-section{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .first-row{
      background: transparent !important;
      .inner{
        background: transparent !important;
        div{
          &:nth-child(2){
            background-image: url(/assets/images/section03/pattern-blue.png);
            background-repeat: repeat; 
          }
        }
      }
    }
    .second-row{
      .inner{
        background-image: url(/assets/images/section03/pattern-yellow.png);
        background-repeat: repeat; 
      }
    }

    .item{
      .inner{        
        height: 100%;                    
        border-radius: 10px;
        border: 2px solid #C2C2C2;
        transition: all .4s ease;

        &:hover{
          border: 2px solid #F9B526;
          -webkit-box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
          -moz-box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
          transition: all .4s ease;
        }

        img{
          border-radius: 0 8px 8px 0;
          position: relative;
          width: 100%;
        }
        h3{
          padding: 0 20px;
          text-align: left;
          width: 100%;
        }
        p{
          padding: 0 20px;
          text-align: left;
          width: 100%;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1000px) {

    .inner-section{
    }
  }

  @media only screen and (max-width: 767px) {

    .inner-section{    
      .item{
        margin: 0 0 20px 0 !important;

        .inner{
          margin: 0 !important;

          img{
            border-radius: 0 0 8px 8px;
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }

</style>