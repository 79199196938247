var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"inner-section"},[_c('h1',{staticClass:"mb-5",staticStyle:{"width":"100%","text-align":"center"},domProps:{"innerHTML":_vm._s(_vm.$t('SECTION_02_TITLE'))}}),_c('div',{staticClass:"carousel-wrapper"},[_c('Carousel',{staticClass:"carousel-section02",attrs:{"margin":0,"autoplay":true,"dots":false,"nav":false,"responsive":{
            0:{
                items: 1
            },
            768:{
                items: 2
            },
            990:{
                items: 3
            },
            1200:{
                items: 4
            }
          }}},_vm._l((_vm.carousel_section02_data),function(item,index){return _c('div',{key:index,staticClass:"carousel-section02-slide"},[_c('div',{staticClass:"inner d-flex flex-column justify-content-start align-items-center"},[_c('img',{staticClass:"mb-4",attrs:{"src":("/assets/images/section02/" + (item.image)),"alt":item.title[_vm.$i18n.locale]}}),_c('h3',{staticClass:"mb-4",domProps:{"innerHTML":_vm._s(item.title[_vm.$i18n.locale])}}),_c('p',{domProps:{"innerHTML":_vm._s(item.text[_vm.$i18n.locale])}})])])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }