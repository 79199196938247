<template>
    <footer class="footer">

      <div class="footer-columns">
        
        <div class="row line m-0">
          <div class="col-lg-5 col-md-5 col-sm-12 d-flex flex-column">
            <p class="title" v-html="$t('FOOTER_COL_01_TITLE')"></p>
            <p v-html="$t('FOOTER_COL_01_SUBTITLE')"></p>
          </div>
          
          <div class="ghost-column col-lg-1 col-md-1 col-sm-12 d-flex flex-column"></div>

          <div class="col-lg-2 col-md-2 col-sm-12 d-flex flex-column">
            <p class="title" v-html="$t('FOOTER_COL_02_TITLE')"></p>
            <a href="#" @click.prevent="$root.$emit('scrollerClicked', 'section02')">{{$t('BENEFITS')}}</a>
            <a href="#" @click.prevent="$root.$emit('scrollerClicked', 'section03')">{{$t('DIFFERENTIAL')}}</a>
            <a href="#" @click.prevent="$root.$emit('scrollerClicked', 'section04')">{{$t('PLANS')}}</a>
            <a href="#" @click.prevent="$root.$emit('scrollerClicked', 'section05')">{{$t('HOW_WORKS')}}</a>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-12 d-flex flex-column">
            <p class="title" v-html="$t('FOOTER_COL_03_TITLE')"></p>
            <a class="mb-2" href="tel:17863535568" target="_blank">+1(786) 353-5568</a>
            <a class="mb-2" href="mailto:help@acquafy.com" target="_blank">help@acquafy.com</a>
            <p>6000 Metrowest Blvd ste 203<br>
            Metrowest - 32835<br>
            Orlando - FL</p>
          </div> 

          <div class="col-lg-2 col-md-2 col-sm-12 d-flex flex-column">

            <p class='title' v-html="$t('FOLLOW_US')"></p>            
            <div class="d-flex flex-row social-media">
              <a class="mr-3" href="#" target="https://facebook.com"><i class="fab fa-facebook-f"></i></a>
              <a class="mr-3" href="#" target="https://instagram.com"><i class="fab fa-instagram"></i></a>
              <a class="mr-3" href="#" target="https://youtube.com"><i class="fab fa-youtube"></i></a>
            </div>       
          </div>
        </div>

        <div style="margin: 60px 0 0 0; width: 100%; text-align: center;">
          <p>
            Acquafy © {{ new Date().getFullYear() }}. {{ $t('ALL_RIGHTS_RESERVED') }}.
          </p> 
        </div>
      </div>
      
      <template>

        <a :href="`https://wa.me/${whatsAppPhone}`" target="_blank" class="whatsapp-link" :class="{'closed': !showCommunicationLinks}">
          <i class="fab fa-whatsapp"></i>
        </a>

        <a class="communicationLinks" @click.prevent="toggleCommunicationLinks">
          <i :class="{
            'fas fa-comments': !showCommunicationLinks,
            'fas fa-times-circle': showCommunicationLinks
          }"></i>        
        </a>

      </template>

    </footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    components:{
    },
    props: ['window'],
    data(){
      return {
        showCommunicationLinks: false,
      }
    },
    
    computed: {
        whatsAppPhone(){
          return "13218887963"
        }
    }, 
         
    methods: {
      ...mapActions('MainStore', [              
      ]), 

      toggleCommunicationLinks(){
        this.showCommunicationLinks = !this.showCommunicationLinks
      },

    },  

    mounted(){                
    }
}
</script>

<style lang="scss" scoped>

.footer-columns{
  background-color: #eee;  
  padding: 5rem 6vw 2rem 6vw;

  p{
    font-size: 14px;
    color: rgba(0,0,0, 0.6);
    &.title{
       color: #2d77b3;
    }
  }
  a{
    font-size: 14px;
    color: rgba(0,0,0, 0.6);
    text-decoration: none;
    transition: all .4s ease;
    &:hover{
      color: #208de5;
      transition: all .4s ease;
    }
  }

  .social-media{
    a{      
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 5px;
      color: #fff;
      background-color: rgba(0,0,0, 0.3);

      &:hover{
        background-color: #208de5;
      }
      i{
        color: #fff;
        font-size: 18px;
      }
    }
  }
}

.whatsapp-link{
  z-index: 999997;
  background-color: #00E676;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 90px;
  right: 20px;
  text-decoration: none;
  opacity: 1;
  transition: all .4s ease;

  &.closed{
    opacity: 0;
    bottom: 20px;
    transition: all .4s ease;
  }

  i{
    color: #fff;
    font-size: 35px;
  }
}

.communicationLinks{
  z-index: 999998;
  cursor: pointer;
  background-color: #D85060;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-decoration: none;
  transition: all .4s ease;

  i{
    color: #fff;
    font-size: 35px;
  }
}



@media only screen and (max-width: 768px) {
  .footer-columns{
    background-color: #eee;  
    padding: 5rem 6vw 2rem 6vw;

    .col-sm-12{
      margin-bottom: 30px;
    }
    .ghost-column{      
      margin-bottom: 0;
    }

    p, a{
      width: 100%;
      text-align: center;
    }

    .social-media{
      justify-content: center;
      a{
        margin: 0 10px;
      }
    }
  }
}

</style>