import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState({
            api: ({ MainStore }) => MainStore.api,
            plans: ({ Lease }) => Lease.plans,            
        }),
    },
    data(){
        return{
            selectedImageIndex: 0,
            selectedPlan: null,            
            showPlanDetails: false,
            showDiscountedPrices: false,
            userCurrency: "usd",
            plans_legend: {
                pt_br: "*Imagens meramente ilustrativas<br>Contrato com prazo de 36 meses<br>As parcelas são descontadas mensalmente no cartão<br>Válido para pessoa física e jurídica<br>O valor da adesão varia de acordo com o plano escolhido",
                en: "*Merely illustrative images <br> contract with a term of 36 months <br> parcels are discounted monthly on the card <br> Valid for individual and legal person <br> The adhesion value varies according to the chosen plan",
                es: "*Meramente imágenes ilustrativas <br> Contrato con un término de 36 meses <br> las parcelas se descuentan mensualmente en la tarjeta <br> la persona válida para la persona individual y jurídica. El valor de adherencia varía según el plan elegido.",
            }
        }
    },

    watch:{
        selectedPlan:{
            deep: true,
            handler(selectedPlan){
                this.$root.$emit("setSelectedPlan", selectedPlan)
            }
        }
    },
        
    methods:{

        setSelectedPlan(plan){

            var selected_prices = this.getFirstPaymentPrice(plan),
                price_type = this.showDiscountedPrices ? 'price' : 'full_price'
            
            this.selectedPlan = {
                ...plan,
                ...{ price_id: selected_prices[`${price_type}_id`] }
            }
            
        },

        getPlanVariants(plan){

            var plan_variants = [plan]

            if(plan.variant_key){
                for(var i in this.plans){
                    if(this.plans[i].key == plan.variant_key){
                        plan_variants.push(this.plans[i])
                    }
                }
            }

            return plan_variants
        },

        setSelectedImageIndex(index, image){
            this.selectedImageIndex = index
        },

        openPlanDetails(plan){
            this.setSelectedPlan(plan)
            this.showPlanDetails = true
            this.$root.$emit('scrollerClicked', 'section04')
        },

        closePlanDetails(){
            this.selectedPlan = null            
            this.showPlanDetails = false

            setTimeout(() => {
                this.$root.$emit('scrollerClicked', 'section04')
            }, 500)
        },

        getFirstPaymentPrice(plan){

            var prices = this.planPrices(plan),              
                symbol = this.userCurrency == 'usd' ? '$' : 'R$'

            if(this.userCurrency == plan.plan_details.adhesion_fee.currency){                
                prices.price += parseFloat(plan.plan_details.adhesion_fee.value) / 100
                prices.full_price += parseFloat(plan.plan_details.adhesion_fee.value) / 100
            }

            return {
                price_id: prices.price_id,
                full_price_id: prices.full_price_id,
                price: `${symbol} ${parseFloat(prices.price).toFixed(2)}`,
                full_price: `${symbol} ${parseFloat(prices.full_price).toFixed(2)}`,
            }

        },

        getPlanFormattedPrices(plan){     
            var prices = this.planPrices(plan),
                symbol = this.userCurrency == 'usd' ? '$' : 'R$'

            return {
                price_id: prices.price_id,
                full_price_id: prices.full_price_id,
                price: `${symbol} ${parseFloat(prices.price).toFixed(2)}`,
                full_price: `${symbol} ${parseFloat(prices.full_price).toFixed(2)}`,
            }
        },

        planPrices(plan){
            var price_id = null,
                full_price_id = null,
                price = 0,
                full_price = 0

            for(var i in plan.prices){

                if(this.userCurrency == plan.prices[i].currency){

                    const reverseStripePrice = plan.prices[i].value / 100

                    if(plan.prices[i].has_discount){
                        
                        price_id = plan.prices[i]._id
                        price = parseFloat(reverseStripePrice)

                    }else{

                        full_price_id = plan.prices[i]._id
                        full_price = parseFloat(reverseStripePrice)
                    }
                }
            }

            return {
                price_id: price_id,
                full_price_id: full_price_id,
                price: parseFloat(price),
                full_price: parseFloat(full_price),
            }
        },
        
        
        getFeePrice(plan){     
            var price = 0,                
                symbol = this.userCurrency == 'usd' ? '$' : 'R$'

            if(this.userCurrency == plan.plan_details.adhesion_fee.currency){
                const reverseStripePrice = plan.plan_details.adhesion_fee.value / 100
                price = parseFloat(reverseStripePrice)                
            }

            price = price.toFixed(2)

            return {
                price: `${symbol} ${price}`
            }
        } 
    }
}