<template>
  <fieldset style="height: 100%;">
    <legend visible="true" v-html="$t('CONFIRMATION')"></legend>  

        <div class="row line m-0 summary">
            <div class="column col-lg-3 col-md-3 col-sm-12"><img class="icon-data-confirmed" :src="`/assets/images/section04/zipcode-true.svg`" alt="Data confirmed!"></div>
            <div class="column col-lg-4 col-md-4 col-sm-12"><h3 v-html="$t('PERSONAL_DATA')"></h3></div>
            <div class="column col-lg-5 col-md-5 col-sm-12">
                <p v-html="getPersonalData()"></p>
            </div>
        </div>
        <div class="row line m-0 summary">
            <div class="column col-lg-3 col-md-3 col-sm-12"><img class="icon-data-confirmed" :src="`/assets/images/section04/zipcode-true.svg`" alt="Data confirmed!"></div>
            <div class="column col-lg-4 col-md-4 col-sm-12"><h3 v-html="$t('AGREEMENT')"></h3></div>
            <div class="column col-lg-5 col-md-5 col-sm-12">
                <p v-html="getAgreementData()"></p>
            </div>
        </div>
        <div class="row line m-0 summary">
            <div class="column col-lg-3 col-md-3 col-sm-12"><img class="icon-data-confirmed" :src="`/assets/images/section04/zipcode-true.svg`" alt="Data confirmed!"></div>
            <div class="column col-lg-4 col-md-4 col-sm-12"><h3 v-html="$t('SHIPPING_ADDRESS')"></h3></div>
            <div class="column col-lg-5 col-md-5 col-sm-12">
                <p v-html="getShippingData()"></p>
            </div>
        </div>
        <div class="row line m-0 mb-5 summary">
            <div class="column col-lg-3 col-md-3 col-sm-12"><img class="icon-data-confirmed" :src="`/assets/images/section04/zipcode-true.svg`" alt="Data confirmed!"></div>
            <div class="column col-lg-4 col-md-4 col-sm-12"><h3 v-html="$t('BILLING_DATA')"></h3></div>
            <div class="column col-lg-5 col-md-5 col-sm-12">
                <p v-html="getBillingData()"></p>
            </div>
        </div>

        <!-- 
            Google Recaptcha + Submit
        -->  
        <div class="row line m-0">
            <div class="col-12 text-right div-recaptcha">
                <div class="recaptcha-wrapper">
                    <vue-recaptcha
                        ref="recaptcha"
                        @verify="recaptchaOnVerify"
                        @expired="recaptchaOnExpired"                
                        :sitekey="google.recaptcha.site_key"                
                        :loadRecaptchaScript="true"
                    ></vue-recaptcha>
                    <label v-html="recaptchaMessage"></label>
                </div>                
            </div>
        </div>
    </fieldset>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RecaptchaMixin from '/src/mixins/RecaptchaMixin'
export default {
    mixins: [RecaptchaMixin],      
    props: ['isBusy', 'form'],    
    data(){
        return {
        }
    },
    computed:{
        ...mapState({                                                
            google: ({ MainStore }) => MainStore.google,
        }),
    },
    methods:{        
        validateRecaptcha(){
            if (!this.recaptcha) {
                this.recaptchaMessage = this.$t('SOLVE_RECAPTCHA')
                this.$root.$emit('setIsBusy', false)
                return
            }
            
            console.log(">> Recaptcha solved!")
            this.$root.$emit('submitOrder')
        },

        getPersonalData(){
            return `
                ${this.form.personalData.name} ${this.form.personalData.lastname}<br>
                ${this.form.personalData.email}<br>
                ${this.form.personalData.phone}<br>
                ${this.$t(this.form.personalData.id_type.toUpperCase())}: ${this.form.personalData.id_number}
            `
        },
        getAgreementData(){
            return `
                ${this.$t(( (this.form.agreementData.agreement) ? 'AGREEMENT_ACCEPTED':'AGREEMENT_REJECTED' ))}
            `
        },
        getShippingData(){
            return `
                ${this.form.billingData.address_line_1} ${this.form.billingData.address_line_2}<br>
                ${this.form.billingData.city} - ${this.form.billingData.state}<br>
                ${this.$t('ZIPCODE')}: ${this.form.billingData.zipcode}<br>
            `
        },
        
        getBillingData(){
            return `
               ${this.$t('CARD_LAST_4')}: ${this.form.billingData.token.card.last4}<br>
               ${this.$t('EXPIRATION')}: ${this.form.billingData.token.card.exp_month}/${this.form.billingData.token.card.exp_year}<br>
            `
        },
    },
    mounted(){

        console.log(">> Google recaptcha", this.google.recaptcha)

        this.$root.$on('validateRecaptcha', () => {      
            this.validateRecaptcha()
        })
    },
    destroyed(){        
        this.$root.$off(['validateRecaptcha'])
    }
}
</script>

<style lang="scss">
.recaptcha-wrapper{    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    label{
      height: 20px;
      position: relative;
      width: 100%;
    }
}

.summary{
    .column{
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid #2d77b3;
        padding: 30px;
                
        .icon-data-confirmed{
            height: 30px;
        }
        h3{
            width: 100%;
            margin: 0;
            color: #2d77b3;
        }
        p{
            width: 100%;
            &:last-child{
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .div-recaptcha{
        text-align: center !important;

        .recaptcha-wrapper{                
            align-items: center;            
            label{
                height: 20px;
                position: relative;
                width: 100%;
            }
        }
    }
    .summary{
        .column{            
            border-bottom: none;
            padding: 0;

            .icon-data-confirmed{
                height: 30px;
                margin: 20px 0;
            }

            h3{
                width: 100%;
                text-align: center;
                margin: 0;
                color: #2d77b3;
            }
            p{
                width: 100%;
                text-align: center;
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}

</style>