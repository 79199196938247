<template>
    <div>
        <fieldset class="mb-5">
            <legend visible="true" v-html="$t('SHIPPING_ADDRESS')"></legend>  
            <b-form ref="formData" @submit="onSubmit" @reset="onReset" v-if="show">
                <!-- 
                    Address
                -->
                <div class="row line m-0">    
                    <div class="col-lg-6 col-md-6 col-sm-12"> 
                        <b-form-group id="address_line_1" :label="$t('ADDRESS_LINE_1')" label-for="address_line_1">
                            <b-form-input :disabled="isBusy" id="address_line_1" v-model="form.address_line_1" type="text" required></b-form-input>
                        </b-form-group>                                       
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12"> 
                        <b-form-group id="address_line_2" :label="$t('ADDRESS_LINE_2')" label-for="address_line_2">
                            <b-form-input :disabled="isBusy" id="address_line_2" v-model="form.address_line_2" type="text" required></b-form-input>
                        </b-form-group>                                       
                    </div>
                </div>

                <!-- 
                    City, state, zipcode
                -->
                <div class="row line m-0">    
                    <div class="col-lg-6 col-md-4 col-sm-12"> 
                        <b-form-group id="city" :label="$t('CITY')" label-for="city">
                            <b-form-input :disabled="isBusy" id="city" v-model="form.city" type="text" required></b-form-input>
                        </b-form-group>                                       
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12"> 
                        <b-form-group id="state" :label="$t('STATE')" label-for="state">
                            <b-form-select :disabled="isBusy" v-model="form.state" :options="states" class="mr-2"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12"> 
                        <b-form-group id="zipcode" :label="$t('ZIPCODE')" label-for="zipcode">
                            <b-form-input disabled id="zipcode" v-model="zipcode" type="text" required></b-form-input>
                        </b-form-group>                                       
                    </div>
                </div>

                <b-button class="btn-invisible-submit" :disabled="isBusy" type="submit" variant="primary">Hidden Submit</b-button>
                
            </b-form>
        </fieldset>

        <fieldset>
            <legend visible="true" v-html="$t('BILLING_DATA')"></legend>

            <!-- 
                Credit card data (Stripe)
            -->
            <div class="row line m-0" v-if="stripe.publishableKey">
                <div class="col-12" :key="$i18n.locale">                        
                    <b-form-group v-if="!form.token" id="stripeCard" :label="$t('CARD_DATA')" label-for="stripeCard">
                        <StripeElementCard
                            ref="stripeCard"
                            :elementsOptions="{locale: $i18n.locale.replace('pt_br', 'pt-BR')}"
                            :pk="stripe.publishableKey"
                            @token="stripeTokenCreated"
                            @loading="stripeLoadingToken"
                            @error="stripeError"
                        ></StripeElementCard>
                        <small class='text-danger' v-if="showRequirementToken">{{$t('PAYMENT_METHOD_REQUIRED')}}</small>
                    </b-form-group>

                    <div class="card-details text-center" v-if="form.token">
                         <b-alert show>
                             <h3 class="text-info">{{$t('CARD_ALREADY_VALIDATED')}}</h3>
                             <p class="my-4" v-html="`${$t('BRAND')}: <b>${form.token.card.brand}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${$t('EXPIRATION')}: <b>${form.token.card.exp_month}/${form.token.card.exp_year}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *****<b>${form.token.card.last4}</b>`"></p>
                             <a href="#" @click.prevent="form.token = null">{{$t('REPLACE_CARD')}}</a>                                
                         </b-alert>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import $ from 'jquery'
import { mapState, mapActions } from 'vuex'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import States from '/src/mixins/States.json'
export default {
    props: ['isBusy', 'zipcode', 'billingData'],
    components:{
        StripeElementCard,
    },
    data(){
        return {
            showRequirementToken: false,
            isLoadingToken: false,
            show: true,            
            zipcode_mask: '#####',
            states: States,
            form: {
                address_line_1: null,
                address_line_2: null,                
                city: null,
                state: null,
                zipcode: null,
                token: null
            },
            
        }
    },    
    computed:{
        ...mapState({                                    
            stripe: ({ MainStore }) => MainStore.stripe,
        }),
    },
    watch:{
        isLoadingToken(bool){
            this.$root.$emit('setIsBusy', bool)
        }
    },
    methods:{            
        stripeLoadingToken(bool){            
            this.isLoadingToken = bool
        },
        stripeError(e){
            console.log(e)
            this.isLoadingToken = false
            this.isSubmitting = false
        },
        stripeTokenCreated (token) {                        
            this.$root.$emit('setIsBusy', false)
            this.form.token = token

            if(!token){
                return
            }   

            console.log(">> New stripe token created!")
            console.log(">> Validating the rest of the billing form!")
            $('.btn-invisible-submit').click() 
        },

        onSubmit(e) {
            e.preventDefault()

            if(!this.form.token){                
                this.showRequirementToken = true
                return
            }else{
                this.showRequirementToken = false
            }

            this.form.zipcode = this.zipcode

            this.$root.$emit("setBillingData", this.form)
        },
        onReset(e) {
            e.preventDefault()        
            this.form.address_line_1 = null
            this.form.address_line_2 = null            
            this.form.city = null
            this.form.state = null
            this.form.token = null

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    mounted(){

        if(this.billingData && Object.keys(this.billingData).length > 0)
            this.form = this.billingData

        this.$root.$on('validateBillingData', () => {      
            if(!this.form.token){
                this.$refs.stripeCard.submit()
            }else{
                this.$root.$emit('setIsBusy', false)
                console.log(">> Using same card token!")
                console.log(">> Validating the rest of the billing form!")
                $('.btn-invisible-submit').click()
            }
        })
    },
    destroyed() {          
        this.$root.$off([
            'validateBillingData'
        ])
    },
}
</script>

<style lang="scss">

.StripeElement{
    border: 1px solid #ced4da !important;
}

.btn-invisible-submit{
    display: none;
}

</style>