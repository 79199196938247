<template>
    <div>
        <div class="top-bar"></div>
        <div class="top-menu">
            <b-navbar toggleable="lg">
                <b-navbar-brand @click.prevent="activeSection = 'section01'; ($route.name == 'home' ? $root.$emit('scrollerClicked', 'section01') : navigateTo('home'))">
                    <div class='d-flex flex-column justify-content-center align-items-end'>
                        <img class="logo" src="/assets/images/logo.png">
                        <small class='logo-subtitle'>{{$t('WATER_AS_A_SERVICE')}}</small>
                    </div>
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>

                    <!-- 
                        Sections
                    -->
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item class='mx-4' :class="{'active' : activeSection == 'section02'}" @click.prevent="$root.$emit('scrollerClicked', 'section02')">{{$t('BENEFITS')}}</b-nav-item>
                        <b-nav-item class='mx-4' :class="{'active' : activeSection == 'section03'}" @click.prevent="$root.$emit('scrollerClicked', 'section03')">{{$t('DIFFERENTIAL')}}</b-nav-item>
                        <b-nav-item class='mx-4' :class="{'active' : activeSection == 'section04'}" @click.prevent="$root.$emit('scrollerClicked', 'section04')">{{$t('PLANS')}}</b-nav-item>
                        <b-nav-item class='mx-4' :class="{'active' : activeSection == 'section05'}" @click.prevent="$root.$emit('scrollerClicked', 'section05')">{{$t('HOW_WORKS')}}</b-nav-item>
                        <b-nav-item class='mx-4' href="tel:17863535568" target="_blank">+1(786) 353-5568</b-nav-item>
                    </b-navbar-nav>

                    <b-navbar-nav>

                        <!-- 
                            Search bar
                        -->
                        <!-- <b-nav-form class='form-search'>                               
                            <b-form-input v-model="searchString" :class="{'show-input': showSearchInput, 'hide-input': !showSearchInput}" size="sm" class="mr-sm-2"></b-form-input>
                            <a @click.prevent="search()"><i class="fas fa-search"></i></a>
                        </b-nav-form> -->

                        <!-- 
                            Laguage selector
                        -->                    
                        <b-nav-item-dropdown class="mx-2 language-menu" :text="'FLAG'" right>
                            <b-dropdown-item v-for="(item, index) in languages" :key="index" @click="changeLocale(item.lang)">
                                <img :src="`/assets/images/flags/${item.image}`" :alt="item.title"> {{item.title}}
                            </b-dropdown-item>
                        </b-nav-item-dropdown>

                        <!-- 
                            Sign-in Sign-out
                        -->
                        <!-- <b-nav-item-dropdown right>                
                            <template #button-content>Bruno Santos</template>
                            <b-dropdown-item href="#">{{$t('MY_ACCOUNT')}}</b-dropdown-item>
                            <b-dropdown-item href="#">{{$t('SIGN_OUT')}}</b-dropdown-item>
                        </b-nav-item-dropdown> -->
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
export default {    
    inject: ['scrollTo', 'navigateTo', 'limitWords'],
    props: ['window'],
    data(){
        return {
            activeSection: 'section01',
            showSearchInput: false,
            searchString: null
        }
    },
    computed: {
        ...mapState({                                    
            customer: ({ Customer }) => Customer.customer,            
            stripe: ({ MainStore }) => MainStore.stripe,
        }),

        languages(){
            return [
                { 'lang': 'pt_br', 'title': 'Português','image': 'pt_br.png' },
                { 'lang': 'en', 'title': 'English', 'image': 'en.png' },
                { 'lang': 'es', 'title': 'Español', 'image': 'es.png' }
            ]
        },
    },        
    mounted(){
        $('.language-menu a span').html(`<img style='height: 20px;' src='/assets/images/flags/${this.$i18n.locale}.png' alt='${this.$i18n.locale}'>`)

        this.$root.$on('scrollerClicked', (section) => {      
            this.activeSection = section; 
            this.scrollTo(`#${section}`)
        })
    },
    destroyed() {          
      this.$root.$off(['scrollerClicked'])
    },
    methods: {
        ...mapActions('MainStore', [  
            'setLocale'                           
        ]),
        search(){
            if(this.showSearchInput){

                if($.trim(this.searchString) != ''){
                    console.log("Search for", this.searchString)
                    this.searchString = null
                }
                this.showSearchInput = false
            }else{
                this.showSearchInput = true
            }
        },        
        changeLocale(locale){                
            this.$i18n.locale = locale
            this.setLocale(locale)
            this.$moment.locale(locale)

            setTimeout(() => {                
                $('.language-menu a span').html(`<img style='height: 20px;' src='/assets/images/flags/${locale}.png' alt='${locale}'>`)
            }, 500)
        },
    },
}
</script>

<style lang="scss">

.top-bar{
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    width: 100%;
    height: 30px;
    
    background: url(/assets/images/section01/bg-yellow.jpg) no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.top-menu{    
    position: fixed;
    background-color: #fff;
    width: 100%;
    top: 30px;
    right: 0;
    padding: 0 6vw;
    z-index: 999;
    font-family: 'Montserrat Alternates Medium';

    .dropdown-toggle::after{
        color: #2d77b3;
    }

    .navbar{
        .navbar-brand{
            .logo{
                cursor: pointer;
                height: 50px;
            }
            .logo-subtitle{
                color: #2d77b3;
                font-family: 'Montserrat Alternates Medium' !important;
                font-size: 13px;
            }
        }

        .navbar-nav{
            .nav-item{
                transition: all .4s ease;
                border-bottom: 3px solid #fff;
                color: #2d77b3 !important;
                
                .nav-link {                
                    color: #2d77b3 !important;
                    font-family: 'Montserrat Alternates Medium' !important;
                }

                &.active,
                &:hover{
                    border-bottom: 3px solid #e9ab26;
                    transition: all .4s ease;
                }
            } 
            .form-search{
                a{
                    cursor: pointer;
                }
                .hide-input{
                    width: 0;
                    opacity: 0;
                    transition: all .4s ease;
                }
                .show-input{
                    width: 130px;
                    opacity: 1;
                    transition: all .4s ease;
                }
            }   
            .language-menu {
                a.nav-link.dropdown-toggle{
                    color: #FF7A7A!important;    
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }       
                img{
                    height: 20px;
                    margin-right: 5px;
                }
                i{
                    color: #4D4D4D;
                }
            }
        }
    }
}
</style>