<template>
  <fieldset style="height: 100%;">
    <legend visible="true" v-html="$t('AGREEMENT')"></legend>        
        <b-form ref="formData" @submit="onSubmit" @reset="onReset" v-if="show">
            <!-- 
                Agreement
            -->
            <AgreementText></AgreementText>

            <div class="row line m-0 mt-3">
                <div class="col-12"> 
                    <b-form-checkbox required v-model="form.agreement" :value="true" :unchecked-value="false"><span v-html="$t('AGREEMENT_ACCEPT')"></span></b-form-checkbox>
                    <small class='text-danger' v-if="!form.agreement && showRequirement">{{$t('MUST_AGREE')}}</small>
                </div>
            </div>

            <b-button class="btn-invisible-submit" :disabled="isBusy" type="submit" variant="primary">Hidden Submit</b-button>

        </b-form>
    </fieldset>
</template>

<script>
import AgreementText from '../../common/AgreementText.vue'
import $ from 'jquery'
export default {
    components:{
        AgreementText
    },
    props: ['isBusy', 'agreementData'],
    data(){
        return {
            show: true,
            showRequirement: false,            
            form: {
                agreement: false,
            },
        }
    },
    mounted(){
        if(this.agreementData && Object.keys(this.agreementData).length > 0)
            this.form = this.agreementData

        this.$root.$on('validateAgreementData', () => {              
            $('.btn-invisible-submit').click()
            this.$root.$emit('setIsBusy', false)
        })
    },
    destroyed() {          
        this.$root.$off([
            'validateAgreementData'
        ])
    },
    methods: {        
        onSubmit(e) {
            e.preventDefault()
            this.$root.$emit('setIsBusy', false)
            if(this.form.agreement){
                this.showRequirement = false
                this.$root.$emit("setAgreementData", this.form)
            }else{
                this.showRequirement = true
            }
        },
        onReset(e) {
            e.preventDefault()        
            this.form.agreement = false
            
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    }
}
</script>

<style lang="scss">

.btn-invisible-submit{
    display: none;
}



</style>