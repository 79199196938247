<template>
  <div class='section04-wrapper'>
      <div class="section">
        <div class="inner-section" style="width: 100%;">

          <h1 style="width: 100%; text-align: center;" v-if="!startedOrdering" class='mb-3' v-html="$t('SECTION_04_TITLE')"></h1>
          <Plans v-if="!startedOrdering" class='mt-3'></Plans>
          
          <template v-if="form.selectedPlan && isValidZipcode && startedOrdering">

            <div class="steps-wrapper">
              <span class="cross-line"></span>
              <ol class="steps">                
                <li class="step" :class="{'is-active': orderStep == 1, 'is-complete': orderStep > 1}" data-step="1"><label>{{$t('REGISTER')}}</label></li>
                <li class="step" :class="{'is-active': orderStep == 2, 'is-complete': orderStep > 2}" data-step="2"><label>{{$t('AGREEMENT')}}</label></li>
                <li class="step" :class="{'is-active': orderStep == 3, 'is-complete': orderStep > 3}" data-step="3"><label>{{$t('BILLING')}}</label></li>
                <li class="step" :class="{'is-active': orderStep == 4, 'is-complete': orderStep > 4}" data-step="4"><label>{{$t('CONFIRMATION')}}</label></li>
              </ol>
            </div>

            <h1 style="width: 100%; text-align: center;" class='mt-4 mb-1' v-html="$t('SECTION_04_TITLE_02')"></h1>
            <div class="mt-5 row line steps-forms-wrapper">
              <div class="column col-lg-7 col-md-7 col-sm-12">
                <FormPersonalData :isBusy="isBusy" :personalData="form.personalData" v-if="orderStep == 1"></FormPersonalData>
                <FormAgreement :agreementData="form.agreementData" v-if="orderStep == 2"></FormAgreement>
                <FormBillingData :billingData="form.billingData" :zipcode="form.zipcode" v-if="orderStep == 3"></FormBillingData>
                <Confirmation :isBusy="isBusy" :form="form" v-if="orderStep == 4"></Confirmation>
                <Congratulations v-if="orderStep == 5"></Congratulations>
              </div>
              <div class="column col-lg-5 col-md-5 col-sm-12">
                <SelectedPlan :isBusy="isBusy" :form="form"></SelectedPlan>                
              </div>
            </div>

            <div class="mt-5 mb-3 d-flex flex-row justify-content-center align-items-center">
              <button class="mr-4 btn btn-white-darkblue" v-if="orderStep > 1 && orderStep <= 4" :disabled="isBusy" @click.prevent="orderStep--">{{$t('GO_BACK')}}</button>
              <button class="btn btn-dark-blue" v-if="orderStep == 1" :disabled="isBusy" @click.prevent="validatePersonalData">{{$t('CONTINUE')}}</button>
              <button class="btn btn-dark-blue" v-if="orderStep == 2" :disabled="isBusy" @click.prevent="validateAgreementData">{{$t('CONTINUE')}}</button>
              <button class="btn btn-dark-blue" v-if="orderStep == 3" :disabled="isBusy" @click.prevent="validateBillingData">{{$t('CONTINUE')}}</button>
              <button class="btn btn-dark-blue" v-if="orderStep == 4" :disabled="isBusy" @click.prevent="validateRecaptcha">
                {{$t('SUBMIT_ORDER')}}
                <img v-if="isBusy" class="ml-4 loading-submit-icon rotating" src="/assets/images/logo_02.svg">      
              </button>
            </div>

            <b-alert
              style="width: 100%;"
              :show="showError"
              dismissible
              @dismissed="showError = false"
              variant="danger"              
            >
              {{submitOrderError}}
            </b-alert>

            <a v-if="!isBusy && orderStep <= 4" class='mb-4' href="#" @click.prevent="cancelOrdering()">{{$t('CANCEL')}}</a>                                
            
          </template>


        </div>

        <!-- ZIPCODE CHECK MODAL -->
        <b-modal id="zipcodeCheckModal" hide-header hide-footer centered>   

          <div class="row line py-5 px-4">
            <div class="col-12">
              <h3 class="mb-5" style="width: 100%; text-align: center;">{{$t('ZIPCODE_AVAILABILITY')}}:</h3>            
              
              <div class="mb-2 zipcode-wrapper">
                <b-form-input               
                  ref="zipcode"
                  id="zipcode"
                  class="input-zipcode" 
                  :class="{'has-content': form.zipcode != null && form.zipcode.length > 0}"                 
                  v-mask="zipcode_mask" 
                  :placeholder="$t('ZIPCODE')"
                  :formatter="formatZipcode" 
                  v-model="form.zipcode" 
                  required
                  @keydown="checkKeyDown($event)"
                ></b-form-input>            

                <img class="icon-zipcode" v-if="isValidZipcode != null" :src="`/assets/images/section04/zipcode-${isValidZipcode}.svg`" alt="Zipcode validation icon">
                <button class="ml-3 btn btn-zipcode" :disabled="isBusy" @click.prevent="verifyZipcode" v-html="$t('CHECK')"></button>              
              </div>
              <div class="validation-message" :class="{'text-success': isValidZipcode, 'text-danger': !isValidZipcode}" v-html="zipcodeMessage"></div>

              <div class="mt-5 modal-buttons" v-if="isValidZipcode != null">
                <template v-if="isValidZipcode != null && !isValidZipcode">
                  <!-- Buy on acquafy.com -->
                  <a class="mb-3 btn btn-light-blue" href="https://acquafy.com" @click="form.zipcode = null" target="_blank" v-html="$t('ORDER_ON_ACQUAFY')"></a>
                  <!-- Cancel ordering -->
                  <button class="btn btn-dark-blue" @click.prevent="$bvModal.hide('zipcodeCheckModal'); form.zipcode = null" v-html="$t('GO_BACK')"></button>
                </template>
                <!-- Advance to complete order -->
                <button v-if="isValidZipcode != null && isValidZipcode" class="btn btn-dark-blue" @click.prevent="startOrdering()" v-html="$t('PROCEED')"></button>
              </div>

            </div>
          </div>
                
        </b-modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ZipcodesMixin from '/src/mixins/ZipcodesMixin'
import FormPersonalData from './Section04/FormPersonalData.vue'
import FormAgreement from './Section04/FormAgreement.vue'
import FormBillingData from './Section04/FormBillingData.vue'
import Confirmation from './Section04/Confirmation.vue'
import Congratulations from './Section04/Congratulations.vue'
import SelectedPlan from './Section04/SelectedPlan.vue'
import Plans from './Section04/Plans.vue'

export default {
  mixins: [ZipcodesMixin],      
  components:{                  
    FormPersonalData,
    FormAgreement,
    FormBillingData,
    Confirmation,
    Congratulations,
    Plans,
    SelectedPlan
  },
  data(){
    return{          
      showError: false,
      submitOrderError: false,
      isBusy: false, 
      orderStep: 1,
      startedOrdering: false,                         
      form: {
        selectedPlan: null,
        personalData: null,
        agreementData: null,
        billingData: null,              
        zipcode: null
      }
    }
  },
  
  computed:{
    ...mapState({                                    
        customer: ({ Customer }) => Customer.customer,                    
        google: ({ MainStore }) => MainStore.google,
        api: ({ MainStore }) => MainStore.api,
    }),
  },

  methods:{    
    ...mapActions('Lease', [  
      'saveLease'              
    ]),

    checkKeyDown(e){      
      if (e.keyCode === 13) {
        this.verifyZipcode()
      }
    },

    startOrdering(){
      this.startedOrdering = true
      this.$bvModal.hide('zipcodeCheckModal')
      this.$root.$emit('scrollerClicked', 'section04')
    },

    cancelOrdering(){

      this.$bvModal.msgBoxConfirm(this.$t('CANCEL_ORDER'), {
        title: '',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(cancel => {

        if(cancel){
          
          this.isBusy = false
          this.orderStep = 1
          this.startedOrdering = false

          this.form = {
            selectedPlan: null,
            personalData: null,
            agreementData: null,
            billingData: null,              
            zipcode: null
          }

          this.$root.$emit('scrollerClicked', 'section04')
        }
        
      })
    },

    async validatePersonalData(){
      this.isBusy = true
      this.$root.$emit("validatePersonalData")
    },
    async validateAgreementData(){
      this.isBusy = true
      this.$root.$emit("validateAgreementData")
    },
    async validateBillingData(){
      this.isBusy = true  
      this.$root.$emit("validateBillingData")      
    },

    async validateRecaptcha () {   
      this.isBusy = true        
      this.$root.$emit('validateRecaptcha')
    },
  },

  mounted(){
    
    this.$root.$on('setBillingData', (data) => {       
      console.log(">> setBillingData", data)     
      this.form.billingData = data
      this.orderStep++
      this.$root.$emit('scrollerClicked', 'section04')
    })

    this.$root.$on('setAgreementData', (data) => { 
      console.log(">> setAgreementData", data)     
      this.form.agreementData = data
      this.orderStep++
      this.$root.$emit('scrollerClicked', 'section04')
    })

    this.$root.$on('setPersonalData', (data) => {      
      console.log(">> setPersonalData", data)
      this.form.personalData = data
      this.orderStep++
      this.$root.$emit('scrollerClicked', 'section04')
    })

    this.$root.$on('setSelectedPlan', (plan) => {   
      console.log(">> Selected plan", plan)   
      this.form.selectedPlan = plan      
    })

    this.$root.$on('setIsBusy', (bool) => {      
      this.isBusy = bool
    })

    this.$root.$on('submitOrder', async() => {      
      console.log(">> Saving lease data...")

      var data = {...this.form}

      data.selectedPlan = {
        plan_id: data.selectedPlan._id,
        price_id: data.selectedPlan.price_id,
      }

      data.locale = this.$i18n.locale

      // We don't need to upload the image again, it was done on FormPersonalData already
      data.personalData.base64File = null

      await this.saveLease({ data: data, api: this.api }).then((response) => {

        this.orderStep++
        this.isBusy = false
        this.$root.$emit('scrollerClicked', 'section04')

      }).catch((error) => {

        this.submitOrderError = error.responseJSON ? error.responseJSON.message : 'Undefined error'
        this.showError = true
        this.isBusy = false

      })
      
      
    })
    
  },
  destroyed() {          
    this.$root.$off([
      'setIsBusy',      
      'submitOrder',
      'setSelectedPlan',
      'setPersonalData',
      'setBillingData',
      'setAgreementData'
    ])
  },
}
</script>

<style lang="scss">

  .loading-submit-icon{
    width: 20px;
  }

  .section04-wrapper{
    .section{    
      background-color: #F3FDFF;
      min-height: calc(100vh - 125px) !important;     
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; 
    }

    .inner-section{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .steps-forms-wrapper{
    
  }

  .zipcode-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .input-zipcode{
      outline: none;
      height: 35px;
      width: 120px;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: #d8d8d8;
      border-radius: 0;
      border-color: #d8d8d8;
      transition: all .4s ease;

      &.has-content,
      &:focus, 
      &:hover{
        background-color: #fff;
        border-color: #F9B526;
        transition: all .4s ease;
      }
    }

    .icon-zipcode{
      height: 20px;
      margin-left: -30px;
    }

    .btn-zipcode{      
      background-color: #F9B526;
      color: #fff;
      font-size: 1rem;
      line-height: 1rem;
      font-weight: bold;
      padding: 5px 10px;
      transition: all .4s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;

      &:hover{
        background-color: #FAC75B;
        transition: all .4s ease;
      }
    }
  }

  .validation-message{    
    font-size: 13px;
    position: relative;
    width: 100%;
    text-align: center;
  }

  .modal-buttons{    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .btn{
      width: 300px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1000px) {

    .inner-section{
    }
  }

  @media only screen and (max-width: 767px) {

    .inner-section{      
    }
  }

</style>