const en = {
    "ESSENCIAL_COOKIES_LABEL": "Strictly necessary cookies",
    "ESSENCIAL_COOKIES_DESCRIPTION": "These cookies are necessary for the functioning of the website and cannot be disabled on our systems. They are generally defined only in response to actions you take that amount to a service request, such as setting your privacy preferences, logging in or filling out forms. You can configure your browser to block or alert you about these cookies, but some parts of the website will not work. These cookies do not store any personally identifiable information. ",
    "PERFORMANCE_COOKIES_LABEL": "Performance and analytics cookies",
    "PERFORMANCE_COOKIES_DESCRIPTION": "These cookies allow us to count visits and traffic sources so that we can measure and improve the performance of our website. They help us to know which pages are the most and least popular and to see how visitors move on the site. All information that these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you visited our website and we will not be able to monitor your performance. ",
    "FUNCTIONAL_COOKIES_LABEL": "Functional cookies",
    "FUNCTIONAL_COOKIES_DESCRIPTION": "These cookies allow the website to provide enhanced functionality and customization. They can be defined by us or by third party providers whose services we add to our pages. If you do not allow these cookies, some or all of these services may not work properly. ",
    "TARGETING_COOKIES_LABEL": "Targeting cookies",
    "TARGETING_COOKIES_DESCRIPTION": "These cookies can be set on our website by our advertising partners. They can be used by these companies to build a profile of their interests and show relevant ads on other sites. They do not store personal information directly, but are based on the unique identification of your browser and Internet device. If you do not allow these cookies, you will have less targeted advertising. ",
    "COOKIES_MODAL_TITLE": "Privacy Preferences",
    "COOKIES_MODAL_DESCRIPTION": "When you visit any website, it may store or retrieve information in your browser, mainly in the form of cookies. This information can be about you, your preferences or your device and is used primarily to make the site work as you expect. The information does not usually identify you directly, but it can provide a more personalized web experience. As we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category titles to learn more and change our default settings. However, blocking some types of cookies can affect your experience on the site and the services we can offer.  <br> <br> Read <a href='/privacy-policy/{lang}' target='_ blank'>Privacy Policy</a>",
    "COOKIES_DESCRIPTION": "We use cookies to help the operation of our site. If you consent, we will also use cookies to measure and improve the site and customize content and ads. We will share information with advertising and analysis partners. <br> <br> Read the <a href='/privacy-policy/{lang}' target='_ blank'>Privacy Policy</a>. ",
    "MANAGE_COOKIES": "Manage Cookies",
    "ACCEPT_COOKIES": "Accept",    
    "CONTINUE_NAVIGATING": "Go to home",
    "NOT_FOUND_TITLE": "Page not found",
    "NOT_FOUND_MESSAGE": "The page you attempt to access does not exist or you do not have permission to access it",
    "ALL_RIGHTS_RESERVED": "All rights reserved",
    "SUBMIT_ORDER": "Submit order",
    "SOLVE_RECAPTCHA": "It is necessary to solve the Recaptcha",
}
export default en