<template>
    <div style="width: 100%;">        
        <div class="carousel-wrapper" v-if="plans">
            <Carousel  
                v-if="!showPlanDetails"
                class="carousel-section04"
                :margin="0" 
                :autoplay="true"                     
                :dots="false"
                :nav="false"
                :responsive="{
                    0:{
                        items: 1
                    },
                    768:{
                        items: 2
                    },
                    990:{
                        items: 2
                    },
                    1200:{
                        items: 4
                    }
                }"
            >
                <template v-for="(item, index) in plans">
                    <div v-if="item.featured" class="carousel-section04-slide" :key="index">                
                        <span v-if="item.popular" class='most-popular' v-html="$t('MOST_POPULAR')"></span>
                        <div class="inner d-flex flex-column justify-content-between align-items-center">
                            <div>
                                <h3 class='my-4' v-html="item.name"></h3>
                                <img :src="item.images.main" :alt="item.name">
                                <h3 class='my-4' v-html="item.subtitle[$i18n.locale]"></h3>
                            </div>

                            <div class='d-flex flex-column justify-content-center align-items-center' style="width: 100%;">
                                <p class='mb-4'>
                                    <span v-html="$t('FOR')"></span>
                                    <span class="price mx-2">{{(getPlanFormattedPrices(item))[( showDiscountedPrices ? 'price' : 'full_price' )]}}</span>
                                    <span v-html="`${$t('MONTHLY')}`"></span>
                                </p>
                                <b-button class="mt-3 mb-1 btn btn-dark-blue" @click="setSelectedPlan(item)" v-b-modal.zipcodeCheckModal v-html="$t('SUBSCRIBE_NOW')"></b-button>
                                <a class='mb-4' href="#" @click.prevent="openPlanDetails(item)">{{$t('LEARN_MORE')}}</a>                                
                            </div>
                        </div>
                    </div>
                </template>
            </Carousel>
        </div>

        <div v-if="!showPlanDetails && plans" class="m-4 legend" v-html='plans_legend[$i18n.locale]'></div>

        <!-- 
            Plan details
        -->
        <div v-if="selectedPlan && showPlanDetails" class='plan_details'>

            <div class="row line">
                <div class="col-lg-1 col-md-1 col-sm-12"></div>
                <div class="column col-lg-5 col-md-5 col-sm-12">
                    <div class='block'>

                        <div v-if="selectedPlan.variant_key" class="thumbs-horizontal">
                            <img 
                                :class="{'selected': selectedPlan.key == plan_variant.key}" 
                                @click="setSelectedPlan(plan_variant)" 
                                :src="plan_variant.images.gallery[0].sm" 
                                v-for="(plan_variant, index) in getPlanVariants(selectedPlan)" 
                                :key="index" 
                                :alt="plan_variant.name"
                            >
                        </div>

                        <h3 class='my-2' v-html="selectedPlan.subtitle[$i18n.locale]"></h3>

                        <!-- 
                            PLAN_DETAILS
                        -->
                        <template>
                            <span class='mt-4 mb-2 title' v-html="$t('PLAN_DETAILS')"></span>                                                  
                            <p>{{$t('ADHESION_FEE')}}: {{getFeePrice(selectedPlan)[( showDiscountedPrices ? 'price' : 'full_price' )]}}</p>
                            <p>{{$t('MONTHLY_PAYMENT')}}: {{(getPlanFormattedPrices(selectedPlan))[( showDiscountedPrices ? 'price' : 'full_price' )]}}</p>
                            <p>{{$t('AGREEMENT_DURATION')}}: {{selectedPlan.plan_details.agreement.duration}} {{$t(selectedPlan.plan_details.agreement.unit)}}</p>
                            <p class="mb-3" v-html="selectedPlan.plan_details.agreement.description[$i18n.locale]"></p>
                        </template>

                        <!-- 
                            PRODUCT_DETAILS
                        -->
                        <template>
                            <span class='mt-5 mb-2 title' v-html="$t('PRODUCT_DETAILS')"></span>
                            <p v-html="selectedPlan.product_details[$i18n.locale]"></p>
                        </template>

                        <div style="width: 100%;" class="mt-5 d-flex flex-column justify-content-center align-items-center">
                            <b-button class="mb-1 btn btn-dark-blue" v-b-modal.zipcodeCheckModal v-html="$t('SUBSCRIBE_NOW')"></b-button>
                            <a href="#" @click.prevent="closePlanDetails()">{{$t('GO_BACK')}}</a>
                        </div>
                    </div>
                </div>

                <div class="column col-lg-5 col-md-5-col-sm-12">

                    <!-- 
                        Gallery
                    -->
                    <div class='block d-flex flex-row justify-content-between align-items-start'>
                        <div class="thumbs">
                            <img 
                                :class="{'selected': selectedImageIndex == index}" 
                                @click="setSelectedImageIndex(index, image)" 
                                :src="image.sm" 
                                v-for="(image, index) in selectedPlan.images.gallery" 
                                :key="index" 
                                :alt="selectedPlan.name"
                            >
                        </div>
                        <InnerImageZoom class="InnerImageZoom" :src="selectedPlan.images.gallery[selectedImageIndex].md" :zoomSrc="selectedPlan.images.gallery[selectedImageIndex].lg"></InnerImageZoom>
                    </div>


                    <!-- 
                        PRODUCT_CHARACTERISTICS
                    -->
                    <div class='block-no-border'>
                        <template>                         
                            <span class='mt-4 mb-2 title' v-html="$t('PRODUCT_CHARACTERISTICS')"></span>
                            <p class="mb-3" v-html="selectedPlan.product_characteristics[$i18n.locale]"></p>
                        </template>

                        <template>
                            <span class='mt-4 mb-3 title' v-html="$t('CERTIFICATIONS')"></span>
                            <div class="certifications">
                                <img src="/assets/images/section04/certification01.png" alt="Certification">
                                <img src="/assets/images/section04/certification02.png" alt="Certification">
                                <img src="/assets/images/section04/certification03.png" alt="Certification">
                                <img src="/assets/images/section04/certification04.png" alt="Certification">
                                <img src="/assets/images/section04/certification05.png" alt="Certification">
                            </div>
                        </template>
                    </div>
                    
                </div>

                <div class="col-lg-1 col-md-1 col-sm-12"></div>
            </div>
        </div>
    </div>
    
</template>

<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import InnerImageZoom from 'vue-inner-image-zoom'
import Carousel from 'vue-owl-carousel'
import PlansMixin from '/src/mixins/PlansMixin'

export default {
    mixins: [PlansMixin],
    props: ['isBusy'],
    components:{
        Carousel,  
        InnerImageZoom      
    }
}
</script>

<style lang="scss">
  .owl-stage{
    display: table;
    height: 100%;    
    .owl-item{
      position: relative;
      display: table-cell !important;
      height: 100% !important;       
    }
  }
</style>

<style scoped lang="scss">

    .carousel-wrapper{      
        width: 100%;
        .carousel-section04{       
        
            .carousel-section04-slide{
                height: 100%;
                padding: 20px 15px 15px 15px;                

                .most-popular{
                    position: absolute;
                    background-color: #F9B526;
                    color: #fff;
                    padding: 5px;
                    margin-top: -15px;
                    width: 160px;
                    text-align: center;
                    margin-left: 50%;
                    left: -80px;
                }
                
                .inner{        
                    height: 100%;                    
                    border-radius: 10px;
                    border: 2px solid #C2C2C2;
                    padding: 0 15px;
                    background-color: #fff;
                    transition: all .4s ease;

                    &:hover{
                        border: 2px solid #F9B526;
                        -webkit-box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
                        -moz-box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
                        box-shadow: 4px 3px 10px 4px rgba(0, 0, 0, 0.2);
                        transition: all .4s ease;
                    }

                    img{                        
                        position: relative;
                        width: 100%;
                    }
                    h3{
                        font-family: 'Montserrat Alternates'; 
                        padding: 0;
                        text-align: center;
                        width: 100%;
                        font-size: 1.3rem !important;
                        line-height: 1.5rem !important;
                        color: #2d77b3;
                    }
                    p{
                        padding: 0;
                        text-align: center;
                        width: 100%;
                        .price{
                            background-color: #F9B526;
                            color: #fff;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }

    .legend{        
        width: 100%;
        color: rgba(0,0,0,0.6);
        font-size: 12px;
    }

    .plan_details{
        width: 100%;
        display: table;
        
        .column{
            display: table-cell;
            
            &:nth-child(2){
                .block{                    
                    height: 100%;
                }
            }
            
            h3{
                font-family: 'Montserrat Alternates'; 
                padding: 0;
                text-align: center;
                width: 100%;
                font-size: 1.3rem !important;
                line-height: 1.5rem !important;
                color: #2d77b3;
            }

            .block,
            .block-no-border{
                display: flex;
                flex-direction: column;                             
                justify-content: flex-start;
                align-items: flex-start;                                
                
                p{
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
            .block{
                padding: 30px 40px;
                height: auto;
                border-radius: 10px;
                border: 2px solid #C2C2C2;                
                background-color: #fff;
                
                .thumbs{
                    display: flex;
                    flex-direction: column;                             
                    justify-content: center;
                    align-items: center;                         
                    position: relative;
                    height: 100%;
                    padding-right: 10px;
                    border-radius: 8px;
                    img{
                        border-radius: 8px;
                        cursor: pointer;
                        border: 2px solid #fff;
                        width: 100px;
                        margin-bottom: 10px;                                   
                        transition: all 0.4s ease;             
                        &.selected{
                            
                            border: 2px solid #F9B526;
                            -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            transition: all 0.4s ease;
                        }
                    }
                }

                .thumbs-horizontal{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;                         
                    position: relative;
                    width: 100%;
                    margin-bottom: 20px;
                    border-radius: 8px;
                    img{
                        border-radius: 8px;
                        cursor: pointer;
                        border: 2px solid #fff;
                        height: 120px;
                        margin: 0 5px;           
                        transition: all 0.4s ease;             
                        &.selected{
                            border: 2px solid #F9B526;
                            -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
                            transition: all 0.4s ease;
                        }
                    }
                }
                .InnerImageZoom{                    
                    height: 100%;
                    width: 100%;
                }
            }

            .title{                
                background-color: #F9B526;
                color: #fff;
                padding: 5px;
            }

            .certifications{
                display: flex;                
                justify-content: flex-start;
                align-items: center;

                img{
                    height: 50px;
                    margin: 0 10px;
                }
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1000px) {

        .inner-section{
        }
    }

    @media only screen and (max-width: 767px) {

        .inner-section{      
        }
    }

</style>