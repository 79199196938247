import $ from 'jquery'

const state = {    
    plan: null,
    plans: null,
    api: {
        plans: {
            getPlans: "/api/plans",
            getPlan: "/api/plans/{plan}",
        },
        lease: {
            link: "/api/lease"
        }
    },
}

const actions = {

    async saveLease({ commit, state }, payload = null) {

        const url = payload.api.baseUrl + state.api.lease.link

        return await $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: payload.data,
            beforeSend: function(xhr) {    
                console.log(">> Saving new lease (saveLease)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + payload.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {

                if (jqXHR.status === 200) {
                    
                    const lease = response                    
                    console.log(">> Lease saved! (saveLease)!", lease)
                    return lease

                } else {
                    return []
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {                
                console.error(">> Error (saveLease): ", jqXHR, textStatus, errorThrown)
                return textStatus
            }
        })


    },

    async getPlans({ commit, state }, payload) {

        const url = payload.api.baseUrl + state.api.plans.getPlans

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",            
            beforeSend: function(xhr) {
                console.log(">> Getting plans data (getPlans)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + payload.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const plans = response.data
                    commit('MUTATE_PLANS', plans)
                    console.log(">> Got plans data (getPlans)!", plans)
                    return plans
                } else {
                    return {}
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPlans): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })
    },

    async getPlan({ commit, state }, payload) {
        const url = payload.api.baseUrl + state.api.plans.getPlan.replace('{plan}', payload.id)

        return await $.ajax({
            url: url,
            type: "get",
            dataType: "json",            
            beforeSend: function(xhr) {
                console.log(">> Getting plan data (getPlan)...")
                xhr.setRequestHeader('Content-Type', 'application/json')
                xhr.setRequestHeader('Authorization', 'Bearer ' + payload.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {
                if (jqXHR.status === 200) {
                    const plan = response
                    commit('MUTATE_PLAN', plan)
                    console.log(">> Got plan data (getPlan)!", plan)
                    return plan
                } else {
                    return {}
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(">> Error (getPlan): ", jqXHR, textStatus, errorThrown)
                return {}
            }
        })
    },

}

const mutations = {
    MUTATE_PLAN: (state, plan) => (state.plan = plan),
    MUTATE_PLANS: (state, plans) => (state.plans = plans)
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}