<template>
  <div class="agreement-wrapper-page">    
    <div class="section">
      <AgreementText></AgreementText>
    </div>
  </div>
</template>

<script>
import AgreementText from './common/AgreementText.vue'
export default {
  props: ['window'],
  components:{    
    AgreementText,
  },  
}
</script>


<style lang="scss">

  .agreement-wrapper-page{
    padding-top: 125px;  

    .section{            
      min-height: calc(80vh - 125px);    
      padding: 5rem 6vw;  

      .agreement-text{
        height: auto;
        overflow: hidden;
      }
      
    }

    
  }

  @media only screen and (max-width: 767px) {
    .agreement-wrapper-page{
      .section{
        padding: 20px 20px;
      }
    }
  }
</style>