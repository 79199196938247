import $ from 'jquery'
import axios from 'axios'

const state = {    
    locale: 'en',    
    env: process.env.env,
    api: {
        upload: "/api/upload",
        token: null,
        baseUrl: process.env.api_url,
        login: {
            link: "/oauth/token",
            client_id: process.env.login_client_id,
            client_secret: process.env.login_client_secret,
            grant_type: process.env.login_grant_type,
            password: process.env.login_password,
            scope: process.env.login_scope,
            username: process.env.login_username,
        }
    },
    stripe: {
        publishableKey: process.env.stripe_publishableKey
    },
    google: {
        recaptcha: {
            site_key: process.env.google_recaptcha_site_key,
            secret_key: process.env.google_recaptcha_secret_key
        },
        analytics: {
            key: process.env.google_analytics_key
        },
        ads: {
            key: process.env.google_ads_key
        }
    },
    user_monetary: null,
    user_ip: null
}

let monetaries = {
    'usa': {
        ioc: "usa",
        symbol: "$",
        currency_code: "usd",
        separators: {
            cents: ".",
            other: ","
        }
    },
    'bra': {
        ioc: "bra",
        symbol: "R$",
        currency_code: "brl",
        separators: {
            cents: ",",
            other: "."
        }
    }
}

let defaultLocale = "usd",
    defaultUserMonetary = {}

if (defaultLocale == 'bra') {
    defaultUserMonetary = monetaries.bra
} else {
    defaultUserMonetary = monetaries.usa
}

const actions = {

    async setUserMonetary({ commit, state }) {

        commit('MUTATE_USER_MONETARY', defaultUserMonetary)
        console.info(">> Default user monetary set by default")
        
    },

    async setEnv({ commit }, payload) {
        commit('MUTATE_ENV', payload)
    },

    async setLocale({ commit }, payload) {
        console.log(">> Setting new locale", payload)
        commit('MUTATE_LOCALE', payload)
    },

    async setApiToken({ commit, state }) {
        console.log(">> Setting api token...")
        commit('MUTATE_API_TOKEN', null)

        const url = state.api.baseUrl + state.api.login.link
       
        await $.ajax({
            url: url,
            type: "POST", 
            dataType: 'json',
            data: {
                client_id: state.api.login.client_id,
                client_secret: state.api.login.client_secret,
                grant_type: state.api.login.grant_type,
                scope: state.api.login.scope
            },                       
            success: function(response, textStatus, jqXHR) {
                const token = response                
                commit('MUTATE_API_TOKEN', token)
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(" >> Error in app.vue -> setApiToken", textStatus)
            }
        })
    },

    async upload({ commit, state }, payload) {

        const url = state.api.baseUrl + state.api.upload

        return await $.ajax({
            url: url,
            type: "POST",
            enctype: 'multipart/form-data',
            data: payload,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 600000,
            async: true,
            beforeSend: function(xhr) {
                console.log(">> Uploading (upload)...")
                xhr.setRequestHeader('Authorization', 'Bearer ' + state.api.token.access_token)
            },
            success: function(response, textStatus, jqXHR) {
                console.log(">> Uploaded!")
                return response
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error(">> Error (upload): ", jqXHR, textStatus, errorThrown)
            }
        }).always(function() {

        })
    },
}

const mutations = {
    MUTATE_LOCALE: (state, locale) => (state.locale = locale),
    MUTATE_USER_MONETARY: (state, user_monetary) => (state.user_monetary = user_monetary),
    MUTATE_USER_IP: (state, user_ip) => (state.user_ip = user_ip),
    MUTATE_ENV: (state, env) => (state.env = env),
    MUTATE_API_TOKEN: (state, apiToken) => (state.api.token = apiToken)
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}