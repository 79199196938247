<template>
  <div id="app">    
    <b-overlay 
      class="app-overlay"
      :show="isLoading && $route.name != 'NotFound'"
      :variant="variant"
      :opacity="opacity"
      :blur="blur"
      rounded="sm"
    >       
    <template #overlay>
      <img class="loading-icon rotating" src="/assets/images/logo_02.svg">      
    </template>

      <TopMenu 
        :key="`elem1_${$i18n.locale}`"
        :window="window"         
        :isLoading="isLoading" 
      ></TopMenu>    

      <router-view  
        :key="uniqID"                                          
        :window="window" 
        :isLoading="isLoading"
      ></router-view> 

      <Footer 
        :key="`elem2_${$i18n.locale}`"        
        :window="window" 
        :isLoading="isLoading" 
      ></Footer> 

      <Cookies 
        :key="`elem3_${$i18n.locale}`"         
        @cookiesAccepted="cookiesAccepted = true" 
        v-if="!cookiesAccepted"
      ></Cookies> 

    </b-overlay>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TopMenu from './components/common/TopMenu'
import Footer from './components/common/Footer'
import Cookies from './components/common/Cookies'
import $ from 'jquery'

export default {
  name: 'App',
  components: {
    Cookies,
    TopMenu,
    Footer,
  },

  provide: function () {
    return {                  
      limitWords: this.limitWords, 
      scrollTo: this.scrollTo,    
      navigateTo: this.navigateTo 
    }
  }, 

  data(){
    return{      
      uniqID: Math.floor(Math.random() * 100),
      isLoading: true,      
      cookiesAccepted: true,      
      variant: 'info',
      opacity: 1,
      blur: '2px',
      window: {
          width: 0,
          height: 0
      },
    }
  },

  computed: {
    ...mapState({
      api: ({ MainStore }) => MainStore.api,
      google: ({ MainStore }) => MainStore.google,      
      customer: ({ Customer }) => Customer.customer,
    }),
  }, 

  watch:{    
    $route: {
      deep: true,
      async handler(route){                     
        this.changePageMetaTags() 
      }
    },

    cookiesAccepted(bool){
        this.setCookies()
    },
  },

  methods:{

    ...mapActions('MainStore', [          
      'setUserMonetary',      
      'setApiToken',      
      'setLocale',
    ]),

    ...mapActions('Lease', [          
      'getPlans'
    ]),

    changePageMetaTags(){
      
      if(this.$route.meta.title && this.$route.meta.title[this.$i18n.locale]){
        
        $("#title").html(this.$route.meta.title[this.$i18n.locale])

      }else{
        
        console.log(">> Tag title undefined for", this.$i18n.locale)
      }

      if (this.$route.meta.metaTags){
    
        this.$route.meta.metaTags.map(tagDef => {

          const tag = document.createElement('meta')

          if(tagDef.name == 'description' || tagDef.property == 'og:description'){        
            if(tagDef.content && tagDef.content[this.$i18n.locale])
              tagDef.content = tagDef.content[this.$i18n.locale]
          }

          Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key])
          })

          tag.setAttribute('data-vue-router-controlled', '')
          
          return tag
          
        }).forEach(tag => document.head.appendChild(tag))
      }
      
    },

    setDefaultLocale(){     
        const locale = 'pt'

        if(locale !== undefined){
          this.$i18n.locale = locale
          this.setLocale(locale);
          this.$moment.locale(locale)  
        }
    },

    async setCookies(){

      if(this.cookiesAccepted){
        const cookiesSelected = JSON.parse(localStorage.cookiesSelected)

        console.log(">> Selected cookies", cookiesSelected)

        if(cookiesSelected){

          if(cookiesSelected.performance_and_analytics){
            console.log(">> Setting Google cookies...")
            this.setGoogleCookies(this.google)
          }
        }
      }
    },

    handleResize() {
        this.window.width = window.innerWidth
        this.window.height = window.innerHeight
    },

    scrollTo(elem) {

      var elemPos = $(elem).offset().top - $(this.$el).offset().top - 100

      $('html, body').animate({
          scrollTop: elemPos
      }, 500)
    },

    navigateTo(pageName){
      this.$router.push({ 
        name: pageName
      })
    },

    limitWords(str, maxLength){
      
      var arrayStr = str.split(" ")
      var newStr = ''
      var l = maxLength

      for(var i in arrayStr){
        if(i <= l){
          newStr += arrayStr[i] + " "
        }else{
          newStr = $.trim(newStr)
          if(l < arrayStr.length){
            newStr += "..."
          }
          break
        }
      }

      return newStr

    },
  },

  async created(){

    if(!this.api.token){
      await this.setApiToken()
    }      

    await this.getPlans({api: this.api})
 
    this.cookiesAccepted = localStorage.cookiesAccepted || false
    await this.setUserMonetary()
    
    window.addEventListener('resize', this.handleResize)
    this.handleResize()

    this.changePageMetaTags()
    this.setCookies()

  },  

  async mounted(){
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  },
  
  destroyed() {    
    window.removeEventListener('resize', this.handleResize)    
  }
}
</script>

<style lang="scss">

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');
@import url('/assets/fonts/fontie-package/Montserrat-Alternates.css');
@import url('/assets/fonts/fontie-package/Montserrat-Alternates-Medium.css');
@import url('/assets/fonts/fontie-package/Myriad-Pro-Light.css');
@import url('/assets/fonts/fontie-package/Myriad-Pro.css');

@import url('/assets/css/style.min.css');

#app{  
  min-height: 100vh;
  position: relative;
  
  .app-overlay{        
    min-height: 100vh;
    width: 100%;      
    position: fixed;
    top: 0;
    left: 0;

    .position-absolute :nth-child(2){
      position: fixed !important;
      top: 50vh !important;
      .loading-icon{
        width: 100px;
      }
    }
  }

  .page-view{      
    min-height: 100vh;
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 991px) {

  #app{
    .page-view{   
    }
  }

}



/*
 Endless Rotation
*/
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 3s linear infinite;
  -moz-animation: rotating 3s linear infinite;
  -ms-animation: rotating 3s linear infinite;
  -o-animation: rotating 3s linear infinite;
  animation: rotating 3s linear infinite;
}
  
</style>