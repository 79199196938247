<template>
  <fieldset style="height: 100%;">
    <legend visible="true" v-html="'Status'"></legend>  

        <div style="height: 100%;" class="d-flex flex-column justify-content-center align-items-center">
            <h3 v-html="$t('SUCCESS_MESSAGE')"></h3>
            <p v-html="$t('SUCCESS_DESCRIPTION')"></p>
        </div>
        
    </fieldset>
</template>

<script>
export default {            
}
</script>

<style scoped lang="scss">
h3{
    width: 100%;
    text-align: center;
    margin: 0 0 20px 0;
    color: #2d77b3;
}
p{
    width: 100%;
    text-align: center;
    &:last-child{
        margin: 0;
    }
}

</style>