const pt_br = {    
    "ESSENCIAL_COOKIES_LABEL": "Cookies estritamente necessários",
    "ESSENCIAL_COOKIES_DESCRIPTION": "Esses cookies são necessários para o funcionamento do site e não podem ser desativados em nossos sistemas. Eles geralmente são definidos apenas em resposta a ações feitas por você que equivalem a uma solicitação de serviços, como definir suas preferências de privacidade, fazer login ou preencher formulários. Você pode configurar seu navegador para bloquear ou alertá-lo sobre esses cookies, mas algumas partes do site não funcionarão. Esses cookies não armazenam nenhuma informação pessoalmente identificável.",
    "PERFORMANCE_COOKIES_LABEL": "Cookies de desempenho e análise",
    "PERFORMANCE_COOKIES_DESCRIPTION": "Esses cookies nos permitem contar visitas e fontes de tráfego para que possamos medir e melhorar o desempenho do nosso site. Eles nos ajudam a saber quais páginas são as mais e menos populares e a ver como os visitantes se movem no site. Todas as informações que esses cookies coletam são agregadas e, portanto, anônimas. Se você não permitir esses cookies, não saberemos quando você visitou nosso site e não poderemos monitorar seu desempenho. ",
    "FUNCTIONAL_COOKIES_LABEL": "Cookies funcionais",
    "FUNCTIONAL_COOKIES_DESCRIPTION": "Esses cookies permitem que o site forneça funcionalidade e personalização aprimoradas. Eles podem ser definidos por nós ou por provedores terceiros cujos serviços adicionamos às nossas páginas. Se você não permitir esses cookies, alguns ou todos esses serviços podem não funcionar corretamente.",
    "TARGETING_COOKIES_LABEL": "Cookies de segmentação",
    "TARGETING_COOKIES_DESCRIPTION": "Esses cookies podem ser definidos em nosso site por nossos parceiros de publicidade. Eles podem ser usados por essas empresas para construir um perfil de seus interesses e mostrar anúncios relevantes em outros sites. Eles não armazenam informações pessoais diretamente, mas se baseiam na identificação exclusiva de seu navegador e dispositivo de Internet. Se você não permitir esses cookies, terá publicidade menos direcionada.",
    "COOKIES_MODAL_TITLE": "Preferências de Privacidade",
    "COOKIES_MODAL_DESCRIPTION": "Quando você visita qualquer site, ele pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies. Essas informações podem ser sobre você, suas preferências ou seu dispositivo e são usadas principalmente para fazer o site funcionar como você espera. As informações geralmente não identificam você diretamente, mas podem fornecer uma experiência na web mais personalizada. Como respeitamos seu direito à privacidade, você pode optar por não permitir alguns tipos de cookies. Clique nos diferentes títulos das categorias para saber mais e alterar nossas configurações padrão. No entanto, o bloqueio de alguns tipos de cookies pode afetar sua experiência no site e os serviços que podemos oferecer.<br><br> Leia a <a href='/privacy-policy/{lang}' target='_blank'> Política de Privacidade </a>",
    "COOKIES_DESCRIPTION": "Usamos cookies para ajudar o funcionamento do nosso site. Se você consentir, também usaremos cookies para medir e melhorar o site e personalizar o conteúdo e os anúncios. Compartilharemos informações com parceiros de publicidade e análise. <br><br>Leia a <a href='/privacy-policy/{lang}' target='_blank'> Política de Privacidade</a>.",
    "MANAGE_COOKIES": "Gerenciar Cookies",
    "ACCEPT_COOKIES": "Aceitar",
    "BENEFITS": "Benefícios",
    "DIFFERENTIAL": "Diferenciais",
    "PLANS": "Planos",
    "HOW_WORKS": "Como funciona",
    "CUSTOMER_SERVICE": "Atendimento ao cliente",
    "WATER_AS_A_SERVICE": "Water-as-a-Service",
    "SIGN_IN": "Login",
    "SIGN_OUT": "Logout",
    "MY_ACCOUNT": "Minha conta",
    "CONTINUE_NAVIGATING": "Ir para home",
    "NOT_FOUND_TITLE": "Página não encontrada",
    "NOT_FOUND_MESSAGE": "A página que você tentou acessar não existe ou você não possui permissão para acessá-la",
    "ALL_RIGHTS_RESERVED": "Todos os direitos reservados",
    "SUBMIT_ORDER": "Finalizar contratação",
    "SUBSCRIBE_NOW": "Contrate agora",
    "SOLVE_RECAPTCHA": "É necessário resolver o Recaptcha",

    "SECTION_01_TITLE": "Água Alcalina Acquafy: <br>Novos hábitos, mais qualidade de vida",
    "SECTION_01_SUBTITLE": "A água de sua casa ou de sua empresa é 100% pura?",
    "SECTION_01_DESCRIPTION": "Você sabia que com o Purificador da Acquafy você terá 100% água alkaline com pH acima de 9 para usar a vontade livre de bactérias, vírus, ferrugem e cloro.<br>Pronta para beber, cozinhar, lavar frutas e verduras.",
    "DROP_01_TITLE": "Água<br>Alcalina",
    "DROP_02_TITLE": "4 Filtros<br>Poderosos",
    "DROP_02_DESCRIPTION": "Almofada de PP<br>Minerais Alcalinos<br>Filtro de Escala<br>Ultrafiltração UF",
    "FOR": "por",
    "DAILY": "ao dia",
    "MONTHLY": "ao mês*",

    "SECTION_02_TITLE": "Benefícios da água Alcalina Acquafy",
    "SECTION_03_TITLE": "Diferenciais dos purificadores de água alcalina Acquafy",
    "SECTION_04_TITLE": "Produtos e planos Acquafy, escolha o melhor para você",
    "SECTION_04_TITLE_02": "Assinar o purificador Acquafy é muito simples",
    "VALID_ZIPCODE": "Região disponível para instalação",
    "INVALID_ZIPCODE": "Serviço indisponível para a região selecionada.<br>Infelizmente ainda não temos o serviço de locação para a área desejada",
    "NULL_ZIPCODE": "Você precisa informar o CEP",
    "ZIPCODE": "CEP",
    "ZIPCODE_AVAILABILITY": "Verificar disponibilidade",
    "PROCEED": "Avançar",
    "GO_BACK": "Voltar",
    "ORDER_ON_ACQUAFY": "Comprar em acquafy.com",
    "CHECK": "Verificar",

    "PERSONAL_DATA": "Dados Pessoais",
    "AGREEMENT": "Contrato",
    "BILLING_DATA": "Dados de Cobrança",
    "CONFIRMATION": "Confirmação",
    "LEARN_MORE": "Saiba mais",
    "MOST_POPULAR": "Mais contratado",
    "PLAN_DETAILS": "Detalhes do plano",
    "PRODUCT_DETAILS": "Detalhes do produto",
    "PRODUCT_CHARACTERISTICS": "Características do produto",
    "CERTIFICATIONS": "Certificações / EPA Est. No. 99499-FL-1",
    "ADHESION_FEE": "Taxa de adesão (taxa única)",
    "MONTHLY_PAYMENT": "Mensalidade",
    "AGREEMENT_DURATION": "Duração do contrato",
    "MONTHS": "meses",
    "CANCEL_ORDER": "Você realmente deseja cancelar a contratação?",
    "YES": "Sim",
    "NO": "Não",
    "REGISTER": "Cadastro",    
    "BILLING": "Pagamento",
    "SELECTED_PLAN": "Plano selecionado",
    "CONTINUE": "Continuar",
    "DRIVER_LICENSE": "Carteira de motorista",
    "SOCIAL_SECURITY": "Seguro social",
    "PASSPORT": "Passporte",
    "SELECT_AN_ID_TYPE": "Selecione o tipo de ID...",
    "NAME": "Nome",
    "LASTNAME": "Sobrenome",
    "EMAIL_CONFIRMATION": "Confirmação de e-mail",
    "PHONE": "Telefone",
    "ID_NUMBER": "Número de identificação",
    "AGREEMENT_ACCEPT": "<b>Autorização</b>: O Cliente concorda com todos os Termos e Condições contidos neste Contrato e garantem que o assinante esteja autorizado a aderir e assinar este Contrato. Este Contrato é válido quando assinado eletronicamente da aceitação pelo cliente durante a execução do processo de contratação realizado a partir do site https://acquafy.lease.",
    "MUST_AGREE": "Você precisa aceitar os termos do contrato para poder continuar",
    "ADDRESS_LINE_1": "Endereço",
    "ADDRESS_LINE_2": "Complemento",
    "CITY": "Cidade",
    "STATE": "Estado",
    "CARD_DATA": "Dados do cartão",
    "SHIPPING_ADDRESS": "Endereço de entrega",
    "EMAIL_EQUAL": "Os e-mails precisam ser idênticos",
    "INVALID_ID": "Número de identificação inválido",
    "SELECT_COUNTRY": "Selecione o país",
    "SELECT_STATE": "Selecione o estado",
    "CARD_ALREADY_VALIDATED": "Cartão já verificado!",
    "BRAND": "Bandeira",
    "EXPIRATION": "Validade",
    "PAYMENT_METHOD_REQUIRED": "O método de pagamento é obrigatório",
    "REPLACE_CARD": "Substituir cartão",
    "TOTAL_FIRST_PAYMENT": "Total do primeiro pagamento",
    "NEXT_PAYMENTS": "Demais mensalidades",
    "AGREEMENT_ACCEPTED": "Contrato aceito",
    "AGREEMENT_REJECTED": "Contrato rejeitado",
    "CARD_LAST_4": "Cartão final",
    "SECTION_05_TITLE": "É muito simples ter água alcalina na sua casa ou empresa",
    "SUCCESS_MESSAGE": "Plano contratado com sucesso!",
    "SUCCESS_DESCRIPTION": "Você receberá em instantes um e-mail com os dados da sua contratação.",
    "READ_AGREEMENT": "Ler o contrato",
    "SEE_PLAN": "Ver plano selecionado",
    "HIDE_PLAN": "Esconder plano selecionado",
    "SECTION_05_CIRCLE_01": "<b>Escolha</b> o plano",
    "SECTION_05_CIRCLE_02": "<b>Assine</b> online",
    "SECTION_05_CIRCLE_03": "<b>Receba</b> e instale",
    "SECTION_05_CIRCLE_04": "<b>Aproveite!</b>",
    "FOOTER_COL_01_TITLE": "ACQUAFY E COVID-19",    
    "FOOTER_COL_01_SUBTITLE": "Você pode comprar tranquilamente<br><br>Acquafy está empenhada em seguir cuidadosamente todas as observações em relação à contenção da propagação do coronavírus. Desde a fabricação até a entrega de seu produto, ele passou por uma higiene rigorosa.",
    "FOOTER_COL_02_TITLE": "ACQUAFY",
    "FOOTER_COL_03_TITLE": "CONTATO",    
    "FOLLOW_US": "Siga-nos",
    "SUBMIT_ORDER_COUNTDOWN": "Este alerta vai desaparecer depois de {count} segundos...",
    "ID_FILE": "Foto do documento",
    "SEE_IMAGE": "Veja a imagem",
    "MAX_FILE_SIZE_ERROR": "A imagem deve ser menor que 4MB"
}
export default pt_br