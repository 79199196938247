import Vuex from 'vuex'
import Vue from 'vue'

import MainStore from './MainStore'
import Customer from './modules/Customer'
import Lease from './modules/Lease'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        MainStore,        
        Customer,
        Lease
    }
})