<template>
  <fieldset style="height: 100%;">
    <legend visible="true" v-html="$t('PERSONAL_DATA')"></legend>        
        <b-form ref="formData" @submit="onSubmit" @reset="onReset" v-if="show">

            <!-- 
                Name
            -->
            <div class="row line m-0">    
                <div class="col-lg-6 col-md-6 col-sm-12"> 
                    <b-form-group id="name" :label="$t('NAME')" label-for="name">
                        <b-form-input :disabled="isBusy" id="name" v-model="form.name" type="text" required></b-form-input>
                    </b-form-group>                                       
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12"> 
                    <b-form-group id="lastname" :label="$t('LASTNAME')" label-for="lastname">
                        <b-form-input :disabled="isBusy" id="lastname" v-model="form.lastname" type="text" required></b-form-input>
                    </b-form-group>                                       
                </div>
            </div>

            <!-- 
                E-mail
            -->
             <div class="row line m-0">    
                <div class="col-lg-6 col-md-6 col-sm-12"> 
                    <b-form-group id="email" :label="'E-mail'" label-for="email">
                        <b-form-input :disabled="isBusy" id="email" v-model="form.email" type="email" required></b-form-input>
                    </b-form-group>                                       
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12"> 
                    <b-form-group id="email_confirmation" :label="$t('EMAIL_CONFIRMATION')" label-for="email_confirmation">
                        <b-form-input :disabled="isBusy" id="email_confirmation" v-model="email_confirmation" type="email" required></b-form-input>
                        <small class='text-danger' v-if="!form.email != email_confirmation && showRequirementEmail">{{$t('EMAIL_EQUAL')}}</small>
                    </b-form-group>                                       
                </div>
            </div>

            <!-- 
                Phone and ID
            -->
             <div class="row line m-0">    
                <div class="col-lg-4 col-md-4 col-sm-12"> 
                    <b-form-group id="phone" :label="$t('PHONE')" label-for="phone">
                        <b-form-input :disabled="isBusy" v-mask="phone_mask" id="phone" v-model="form.phone" type="tel" required></b-form-input>
                    </b-form-group>                                       
                </div>
                <div class="col-lg-8 col-md-8 col-sm-12"> 
                    <b-form-group id="id_number" :label="$t('ID_NUMBER')" label-for="id_number">
                        <div class="id-container">
                            <b-form-select :disabled="isBusy" v-model="form.id_type" :options="id_types" class="mr-2" @change="setIdMask" required></b-form-select>                            
                            
                            <!-- Only for Driver License -->
                            <b-form-select v-if="form.id_type == 'driver_license'" :disabled="isBusy" v-model="form.id_state" :options="states" class="mr-2" required></b-form-select>
                            <!-- Only for Passport -->
                            <b-form-select v-if="form.id_type == 'passport'" :disabled="isBusy" v-model="form.id_country" :options="countriesList" class="mr-2" required></b-form-select>

                            <b-form-input :disabled="isBusy || !form.id_type" v-mask="id_mask" id="id_number" v-model="form.id_number" type="text" required></b-form-input>                                                        
                        </div>
                        <small class='text-danger' v-if="showRequirementID">{{$t('INVALID_ID')}}</small>
                    </b-form-group>                                       
                </div>
            </div>

            <div class="row line m-0">
                <div class="col">
                    <b-form-group :label="$t('ID_FILE')" label-for="id_file">
                        <b-form-file
                            id="id_file"
                            @change="uploadFile"                                
                            :disabled="isBusy"      
                            :required="!form.base64File"                             
                            accept="image/jpeg, image/png, image/gif"                            
                        ></b-form-file> 
                        
                        <!-- Uploading -->
                        <div class='mt-3 d-flex flex-row justiy-content-center align-items-center' v-if="isBusy">
                            <img class="loading-upload-icon rotating" src="/assets/images/logo_02.svg">      
                            <label class="ml-2"><b>Uploading...</b></label>
                        </div>

                        <!-- Error -->
                        <div class='mt-3 d-flex flex-row justiy-content-center align-items-center' v-if="uploadErrorMessage">                            
                            <label class="ml-2 text-danger">{{uploadErrorMessage}}</label>
                        </div>

                        <!-- Image link -->
                        <div class="mt-3">
                            <a v-if="form.base64File" href="#" v-b-modal.preview-image-modal>{{$t('SEE_IMAGE')}}</a>
                        </div>
                        <b-modal size="lg" id="preview-image-modal" style="background-color: lime;" hide-header hide-footer centered>                            
                            <div style="width: 100%;" class="d-flex justify-content-center align-items-center">
                                <img v-if="form.base64File" :src="form.base64File" class="preview-image" />
                            </div>
                        </b-modal>

                    </b-form-group>
                </div>
            </div>

            <b-button class="btn-invisible-submit" :disabled="isBusy" type="submit" variant="primary">Hidden Submit</b-button>
            
        </b-form>
    </fieldset>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
import States from '/src/mixins/States.json'
import DriversLicencesRules from '/src/mixins/DriversLicencesRules.json'
import Countries from '/src/mixins/Countries.json'
export default {
    props: ['isBusy', 'personalData'],    
    data(){
        return {        
            uploadErrorMessage: false,    
            showRequirementID: false,
            showRequirementEmail: false,
            show: true,
            id_mask: null,
            email_confirmation: null,
            phone_mask: '(###)###-####',
            id_types: [
                {value: null, text: this.$t('SELECT_AN_ID_TYPE') },
                {value: 'driver_license', text: this.$t('DRIVER_LICENSE')},
                {value: 'social_security', text: this.$t('SOCIAL_SECURITY')},
                {value: 'passport', text: this.$t('PASSPORT')},
            ],
            id_types_masks: {
                driver_license: 'A###-###-##-###-#',
                social_security: '###-##-####',
                passport: 'AA######',
            },            
            
            form: {
                base64File: null,
                email: null,                
                name: null,
                lastname: null,
                phone: null,
                id_type: null,
                id_number: null,
                id_state: null, // Only for DL
                id_country: null, // Only for Passport
                id_file: null
            },
            states: [{value: null, text: this.$t('SELECT_STATE')},...States],
            countriesJson: Countries,
            countriesList: [],
            drivers_licences_rules: DriversLicencesRules,
        }
    },
    mounted(){

        this.setCountriesList()

        if(this.personalData && Object.keys(this.personalData).length > 0)
            this.form = this.personalData

        this.$root.$on('validatePersonalData', () => {    
            $('.btn-invisible-submit').click()
            this.$root.$emit('setIsBusy', false)
        })
    },
    destroyed() {          
        this.$root.$off([
            'validatePersonalData'
        ])
    },
    methods: {

        ...mapActions('MainStore', [          
            'upload',
        ]),

        async uploadFile(e){

            this.$root.$emit('setIsBusy', true)

            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            const sizeInMB = (files[0].size / (1024*1024)).toFixed(2);
            
            if(sizeInMB >= 4){
                this.uploadErrorMessage = this.$t('MAX_FILE_SIZE_ERROR')
                this.$root.$emit('setIsBusy', false)
                return
            }   

            /*
                Upload to S3
            */
            const formData = new FormData()
            formData.append('file', $('#id_file')[0].files[0])

            await this.upload(formData).then((response) => {                
                this.form.id_file = response.id
            })

            /*
                Create preview
            */
            var reader = new FileReader()  

            reader.readAsDataURL(files[0])
            reader.onload = async (e) => {                
                this.form.base64File = e.target.result
            }

            this.$root.$emit('setIsBusy', false)    
            
        },

        setCountriesList(){
      
          var countriesList = [
              {value: null, text: this.$t('SELECT_COUNTRY')}
          ]

          for(var i in this.countriesJson){
            var country = this.countriesJson[i],
                name = null,                
                code = country['cca3']
    
            if(this.$i18n.locale == 'en'){                
                name = country['name']['common']
            }else{          
                let lang = this.$i18n.locale
                if(lang == 'pt_br'){
                    lang = 'pt'
                }
                name = country['translations'][lang]['common']
            }

            countriesList.push({
              value: code,
              text: this.$t(`${name}`)
            })

          }

          this.countriesList = countriesList
        },

        setIdMask(id_type){
            this.id_mask = null

            if(id_type != 'driver_license')
                this.id_mask = this.id_types_masks[id_type]            
        },
        async onSubmit(e) {
            e.preventDefault()            
            this.$root.$emit('setIsBusy', false)

            if(
                (this.form.id_type == 'social_security' && !this.ssnValidation()) ||
                (this.form.id_type == 'passport' && !this.passportValidation()) ||
                (this.form.id_type == 'driver_license' && !this.driverLicenseValidation())
            ){                
                this.showRequirementID = true
                return
            }else{
                this.showRequirementID = false
            }

            if(this.form.email == this.email_confirmation){
                this.showRequirementEmail = false
            }else{
                this.showRequirementEmail = true
                return
            }

            this.$root.$emit("setPersonalData", this.form)
        },
        onReset(e) {
            e.preventDefault()       
            
            this.form.base64File = null

            this.form.email = null
            this.form.name = null
            this.form.lastname = null
            this.form.phone = null
            this.form.id_type = null
            this.form.id_number = null   
            this.id_state = null // Only for DL
            this.id_country = null // Only for Passport   
            this.id_file = null

            this.email_confirmation = null

            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },

        
        ssnValidation() {            
            const isValid = this.form.id_number && /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(this.form.id_number)            
            return isValid
        },
        driverLicenseValidation(){
            const regexRules = this.drivers_licences_rules[this.form.id_state]            
            var isValid = false

            for(var i in regexRules){                    
                const rule = new RegExp(regexRules[i].regex)

                if(rule.test(this.form.id_number)){
                    isValid = true
                    break
                }
            }
            return isValid
        },
        passportValidation(){                
            const rule = new RegExp("^(?!^0+$)[a-zA-Z0-9]{6,9}$"),
                  isValid = rule.test(this.form.id_number)

            return isValid            
        }
    }
}
</script>

<style lang="scss">

.btn-invisible-submit{
    display: none;
}

.preview-image{
    position: relative;
    width: 100%;
}
.loading-upload-icon{
    width: 30px;
}

.id-container{
   display: flex;
   flex-direction: row;
   align-items: center; 
}

 @media screen and (max-width: 600px)  {
    .id-container{    
        flex-direction: column;    
        select, input{
            margin: 0 0 20px 0 !important;
        }
    }
}



</style>