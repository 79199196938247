import VueRecaptcha from 'vue-recaptcha'

export default {
    components:{
        VueRecaptcha
    },    
    data(){
        return {
            recaptcha: null,
            recaptchaMessage: null,
        }
    },
    methods:{
        recaptchaOnVerify(response){
            console.log('>> Recaptcha verified!')
            this.recaptcha = response  
            this.recaptchaMessage = null          
        },
        recaptchaOnExpired(){
            console.log('>> Recaptcha has expired!')
            this.recaptcha = null
            this.recaptchaMessage = null
        },
        recaptchaReset(){
            console.log('>> Recaptcha has been reset!')
            this.recaptcha = null  
            this.recaptchaMessage = null
        }
    }
}